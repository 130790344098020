/* eslint-disable no-nested-ternary */
import { LessonStatusTypes, PostRescheduleLessonDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { atom, useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { DateTime as DT } from 'luxon';
import { useEffect } from 'react';
import { LessonPlanService } from '../../services/LessonPlan/LessonPlanService';
import { atomApiWithNavAndRead, atomApiWithNavAndUpdate } from '../../utils/async-atom';
import { LessonPlanUrl } from '../Utils';
import { DialogOperation, DialogStages, LessonPlanRescheduleState, RescheduleStateOptions, SaveReschedule } from './LessonPlanTypes';
import { AddDays } from '../../utils/TimeHelper';
import { LESSON_PLAN_DAYS_AHEAD } from '../../config/constants';
import { SchoolService } from '../../services/School';

export const lessonPlanCopyDialogAtom = atom<LessonPlanRescheduleState | undefined>(undefined);

export const scheduledSessionsAtom = atomApiWithNavAndRead(async (get, nav) => {
  if (!nav?.schoolId || !nav?.studentId || !nav.lessonPlanId) return Promise.resolve(undefined);

  const originalLessonPlan = await get(LessonPlanService).get(nav.schoolId, nav.studentId, nav.lessonPlanId);
  const startDate = originalLessonPlan?.startDateTime ? DT.fromISO(originalLessonPlan.startDateTime).toJSDate() : new Date();

  const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
  const data = await get(LessonPlanService).getAllForStudent(nav.schoolId, nav.studentId, {
    start: startDate,
    end: AddDays(startDate, LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, false),
  });

  const filtered = data.filter((s) => s.lessonStatus === LessonStatusTypes.Scheduled);
  return filtered;
});

const useLog = (obj: any, label?: string) => {
  useEffect(() => {
    // console.log(label ? { [label]: obj } : obj);
  }, [obj]);
};

export const createSessionAtom = atomApiWithNavAndUpdate(
  // Read scheduled session
  async (get, nav) => Promise.resolve(''),
  async (get, set, saveValues: SaveReschedule, nav) => {
    const dialogState = get(lessonPlanCopyDialogAtom);
    if (!dialogState) {
      console.error('createSessionAtom', 'no state');
      return Promise.resolve('');
    }
    const lessonPlanService = get(LessonPlanService);
    const schoolId = nav?.schoolId;
    const { studentId, post } = saveValues;

    const { result, error: apiError } = await lessonPlanService.postRescheduleLessonPlan(
      schoolId!,
      studentId,
      post as unknown as PostRescheduleLessonDto,
    );
    if (apiError) {
      set(lessonPlanCopyDialogAtom, {
        ...dialogState,
        state: { ...dialogState.state, stage: DialogStages.Conflict, error: apiError, message: 'error' },
      });
      return Promise.resolve('error');
    }

    if (result) {
      set(lessonPlanCopyDialogAtom, {
        ...dialogState,
        state: { ...dialogState.state, stage: DialogStages.Done, redirect: LessonPlanUrl(result) },
      });
    }

    return Promise.resolve({ message: '', type: 'client' });
  },
);

export const useLessonPlanCopyDialog = () => {
  const createSession = useUpdateAtom(createSessionAtom);
  const [dialogState, setDialogState] = useAtom(lessonPlanCopyDialogAtom);
  useLog(dialogState, 'useLessonPlanCopyDialog');
  const state = dialogState?.state ?? { stage: DialogStages.Closed };
  const stage = dialogState?.state.stage ?? DialogStages.Closed;

  const startDuplicate = (originalSession: SessionLessonPlanDto) =>
    setDialogState({ state: { stage: DialogStages.Start }, dialogOperation: DialogOperation.Duplicate, originalSession });

  // Verify schedule is an absence before allowing makeup
  const startMakeup = (originalSession: SessionLessonPlanDto) =>
    setDialogState({ state: { stage: DialogStages.Start }, dialogOperation: DialogOperation.MakeUp, originalSession });

  const closeDialog = () => setDialogState(undefined);

  const setStage = (newStage: DialogStages.Start | DialogStages.ScheduledSession | DialogStages.CustomSession) => {
    setDialogState(dialogState === undefined ? undefined : { ...dialogState, state: { stage: newStage } });
  };
  // prettier-ignore-start
  const getPending = (
    previousStage: DialogStages.ScheduledSession | DialogStages.CustomSession,
  ): (RescheduleStateOptions & { stage: DialogStages.Pending }) | undefined =>
    dialogState === undefined
      ? undefined
      : dialogState.state.stage !== previousStage
      ? undefined
      : dialogState.state.stage === DialogStages.ScheduledSession
      ? dialogState.state.scheduledSession
        ? { stage: DialogStages.Pending, scheduledSession: dialogState.state.scheduledSession }
        : undefined
      : dialogState.state.startDate && dialogState.state.endDate
      ? { stage: DialogStages.Pending, startDate: dialogState.state.startDate, endDate: dialogState.state.endDate }
      : undefined;
  // prettier-ignore-end

  const setPending = (previousStage: DialogStages.ScheduledSession | DialogStages.CustomSession) => {
    if (dialogState !== undefined) {
      const newState = getPending(previousStage);
      if (newState !== undefined) {
        const newDialogState = { ...dialogState, state: newState };
        const start = 'startDate' in newState ? newState.startDate.toISOString() : newState.scheduledSession.startDateTime;
        const end = 'endDate' in newState ? newState.endDate.toISOString() : newState.scheduledSession.endDateTime;

        setDialogState(newDialogState);
        createSession({
          post: {
            originalLessonId: newDialogState.originalSession.id,
            startDate: DT.fromISO(start).toUTC().toISO(),
            endDate: DT.fromISO(end).toUTC().toISO(),
            byUserId: newDialogState.originalSession.byUserId,
            weekDay: 'scheduledSession' in newState ? newState.scheduledSession.weekDay : undefined,
            weekOrdinal: 'scheduledSession' in newState ? newState.scheduledSession.weekOrdinal : undefined,
            makeUp: newDialogState.dialogOperation === DialogOperation.MakeUp,
          },
          studentId: newDialogState.originalSession.studentId,
        });
        return;
      }
    }

    console.error('setPending', dialogState, previousStage);
  };

  const setToEntry = (previousStage: DialogStages.Conflict | DialogStages.Pending) => {
    if (dialogState?.state.stage !== previousStage) return;

    const newState: RescheduleStateOptions | undefined =
      'scheduledSession' in dialogState.state
        ? { stage: DialogStages.ScheduledSession, scheduledSession: dialogState.state.scheduledSession }
        : dialogState.state.startDate && dialogState.state.endDate
        ? { stage: DialogStages.CustomSession, startDate: dialogState.state.startDate, endDate: dialogState.state.endDate }
        : undefined;

    if (newState !== undefined) {
      setDialogState({ ...dialogState, state: newState });
      return;
    }

    console.error('setToEntry', dialogState, previousStage);
  };

  const setTimeFrame = (
    newStage: DialogStages.ScheduledSession | DialogStages.CustomSession,
    timeFrame: { startDate?: Date; endDate?: Date; scheduledSession?: SessionLessonPlanDto },
  ) =>
    setDialogState((current) =>
      current === undefined
        ? undefined
        : newStage === DialogStages.ScheduledSession
        ? { ...current, state: { stage: newStage, scheduledSession: timeFrame.scheduledSession } }
        : { ...current, state: { stage: newStage, startDate: timeFrame.startDate, endDate: timeFrame.endDate } },
    );

  const hookState = { state, dialogOperation: dialogState?.dialogOperation };

  return [
    hookState,
    stage,
    {
      setState: setDialogState,
      setTimeFrame,
      setStage,
      setPending,
      setToEntry,
      startDuplicate,
      startMakeup,
      closeDialog,
    },
  ] as [
    typeof hookState,
    DialogStages,
    {
      // use the typescript generic to get the type of the function
      setState: typeof setDialogState;
      setTimeFrame: typeof setTimeFrame;
      setStage: typeof setStage;
      setPending: typeof setPending;
      setToEntry: typeof setToEntry;
      startDuplicate: typeof startDuplicate;
      startMakeup: typeof startMakeup;
      closeDialog: typeof closeDialog;
    },
  ];
};
