import { Box, Divider, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React from 'react';
import { SchoolServiceAtoms } from '../../services/School';
import { StudentTutorSchedule } from './StudentTutorSchedule';

export interface GradeSchedulesProps {
  gradeLevel: number;
}

export const GradeSchedules: React.FunctionComponent<GradeSchedulesProps> = ({ gradeLevel }) => {
  const sta = useAtomValue(SchoolServiceAtoms.getCurrentSchoolTutorAssignments);
  const school = useAtomValue(SchoolServiceAtoms.getCurrentSchool);

  const forGradeAndActive = sta?.filter((s) => s.gradeLevel === gradeLevel && s.firstName) ?? [];

  if (!sta || sta.length === 0) {
    return (
      <Box>
        <Text as="i">No Students available</Text>
      </Box>
    );
  }

  return (
    <>
      {forGradeAndActive.map((s, i) => (
        <Box padding="20px 0px" key={i}>
          <StudentTutorSchedule schoolId={school?.id as number} studentName={s.fullName} studentId={s.studentId} />
          <Divider borderColor="ff.pink" />
        </Box>
      ))}
    </>
  );
};
