import { toNum } from '../utils/utils';

export const LESSON_PLAN_INTERVAL_MINS = 15;
export const FAMILY_EVENT_INTERVAL_MINS = 15;
export const PAGINATION_ROW_OPTIONS = [10, 20, 30, 40, 50];
export const LESSON_PLAN_CURRENT_START_DATE = process.env.REACT_APP_LESSON_PLAN_CURRENT_START_DATE ?? '2022/09/01';
export const LESSON_PLAN_DAYS_BEHIND = toNum(process.env.REACT_APP_LESSON_PLAN_DAYS_BEHIND) ?? 120;
export const LESSON_PLAN_DAYS_AHEAD = toNum(process.env.REACT_APP_LESSON_PLAN_DAYS_AHEAD) ?? 28;
export const ENABLE_STATS_WIDGETS = false;

export const STORYBOOK_API = 'http://localhost:6009'; //  process.env.REACT_APP_FF_STORYBOOK_API ?? 'http://localhost:6006';
export const COLLECT_API_CALLS = process.env.REACT_APP_FF_COLLECT_API_CALLS?.toLowerCase() === 'true';
export const STORYBOOK_ON = process.env.STORYBOOK?.toLowerCase() === 'true';
