import { AxiosResponse } from 'axios';
import {
  PatchStudentDto,
  PutStudentTutorAssignmentDto,
  StudentDto,
  StudentEnrollmentDto,
  StudentUserScheduleDto,
} from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { persistNavAtom, persistNavPropsAtom } from '../atoms/navAtom';
import { atomApiWithNavAndRead } from '../utils/async-atom';
import { MetricDto } from '../components/MetricCard';
import { fixDates } from '../utils/fix-api-dates';

const studentServiceFactory = (get: Getter) => ({
  getStudent: async (schoolId: number, studentId: number): Promise<StudentDto> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/`);
    return response.data as StudentDto;
  },
  getStudents: async (schoolId: number): Promise<StudentDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/`);
    // console.log(`studentServiceFactory: getStudents: response.data.results: `, response.data.results);
    return response.data.results as StudentDto[];
  },
  getStudentEnrollment: async (schoolId: number, studentId: number): Promise<StudentEnrollmentDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/enrollment/`);
    // console.log(`studentServiceFactory: getStudents: response.data.results: `, response.data.results);
    return response.data.results as StudentEnrollmentDto[];
  },
  activateStudent: async (
    schoolId: number,
    studentId: number,
  ): Promise<void> => // Follow
    get(apiClientAtom).put(`/schools/${schoolId}/students/${studentId}/activate`),
  deactivateStudent: async (
    schoolId: number,
    studentId: number,
  ): Promise<void> => // Follow
    get(apiClientAtom).put(`/schools/${schoolId}/students/${studentId}/deactivate`),
  putTutorAssignments: async (
    // Follow
    schoolId: number,
    assignments: PutStudentTutorAssignmentDto[],
  ): Promise<StudentUserScheduleDto[]> => {
    const response = await get(apiClientAtom).put(`/schools/${schoolId}/tutor-assignments/`, assignments);
    return response.data as StudentUserScheduleDto[];
  },
  putStudent: async (schoolId: number, studentId: number, put: any): Promise<void> => {
    // Follow
    await get(apiClientAtom).put(`/schools/${schoolId}/students/${studentId}`, put);
  },
  patchStudentEnrollment: async (schoolId: number, patch: PatchStudentDto[]): Promise<void> => {
    // Follow
    await get(apiClientAtom).patch(`/schools/${schoolId}/students/enrollment/`, patch);
  },
  deleteStudentEnrollment: async (schoolId: number, studentId: number, enrollmentId: number): Promise<void> => {
    await get(apiClientAtom).delete(`/schools/${schoolId}/students/${studentId}/enrollment/${enrollmentId}`);
  },
});

const studentMetricsServiceFactory = (get: Getter) => ({
  getStudentMetrics: async (schoolId: number | undefined, studentId: number | undefined): Promise<any> => {
    const response = await Promise.all([
      get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/metrics/reading-level/`) as Promise<AxiosResponse<MetricDto[]>>,
      get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/metrics/family-engagement/`) as Promise<
        AxiosResponse<MetricDto[]>
      >,
      get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/metrics/attendance/`) as Promise<AxiosResponse<MetricDto[]>>,
    ]);
    return response.flatMap((res) => res.data);
  },
});

const StudentService = atom(studentServiceFactory);
export { StudentService };

export const StudentServiceAtoms = {
  getCurrentStudent: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.studentId || !nav.schoolId) return undefined;
    return studentServiceFactory(get).getStudent(nav.schoolId, nav.studentId);
  }),
  getCurrentStudents: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return studentServiceFactory(get).getStudents(nav.schoolId);
  }),
  getCurrentStudentEnrollment: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return undefined;
    return studentServiceFactory(get)
      .getStudentEnrollment(nav.schoolId, nav.studentId)
      .then((rows) => fixDates(rows ?? [], ['entryDate', 'exitDate']) as StudentEnrollmentDto[]);
  }),

  // Needs a separate atom so that only the attendance table goes into suspense
  getCurrentStudentsFamilyEventsAttendance: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return [];
    return studentServiceFactory(get).getStudents(nav.schoolId);
  }),
  getStudentMetrics: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return [];
    return studentMetricsServiceFactory(get).getStudentMetrics(nav.schoolId, nav.studentId);
  }),
};

StudentServiceAtoms.getCurrentStudentEnrollment.debugLabel = 'getCurrentStudentEnrollment';

export const getNavStudents = atom(async (get) => {
  const { schoolId } = get(persistNavAtom);

  if (!schoolId) return undefined;

  const response = await get(apiClientAtom).get(`/schools/${schoolId}/students`);

  return response.data.results as StudentDto[];
});
