import React, { ReactNode } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import { DateTime as DT } from 'luxon';
import { isDate, isString } from 'lodash';
import { DateTimePicker } from '../DateTimePicker';

export interface FfFormikDateTimePickerProps {
  label: ReactNode;
  id: string;
  isDisabled?: boolean;
  filterDate?: (date: Date) => boolean;
  timeIntervals?: number;
  showTimeSelect?: boolean;
  selectedDate?: Date | undefined;
  useDate?: boolean;
  onChange?: (date: Date) => void;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  excludeDateIntervals?: any;
  dateFormat?: string;
}

export const FfFormikDateTimePicker: React.FunctionComponent<FfFormikDateTimePickerProps> = (props: FfFormikDateTimePickerProps) => {
  const [dateTime, setDateTime] = React.useState<Date | undefined>(props.selectedDate);
  const onChange = (date: Date, form: any, field: any) => {
    // Set as Date in internal state, string for form value
    setDateTime(date);
    if (date) form.setFieldValue(props.id, isString(field.value) ? date.toISOString() : date);
    if (props.onChange) props.onChange(date);
  };
  const selectedDate = (field: any): Date | undefined => {
    const selected = dateTime ?? field.value;
    return isDate(selected) ? selected : DT.fromISO(selected).toJSDate();
  };
  //  dateTime ?? (props.useDate && !!field.value) ? field.value : DT.fromISO(field.value).toJSDate();

  return (
    <Field id={props.id} name={props.id}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => (
        <FormControl isDisabled={props.isDisabled || form.isSubmitting} isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
          <div style={{ position: 'relative', zIndex: '100' }}>
            <DateTimePicker
              showTimeSelect={props.showTimeSelect}
              // Allow datetime to be selected via prop or field value
              selectedDate={selectedDate(field)}
              timeIntervals={props.timeIntervals}
              onChange={(e) => onChange(e, form, field)}
              minDate={props.minDate}
              maxDate={props.maxDate}
              excludeDateIntervals={props.excludeDateIntervals}
              dateFormat={props.dateFormat}
            />
            {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
          </div>
        </FormControl>
      )}
    </Field>
  );
};
