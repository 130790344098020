/* eslint-disable no-nested-ternary */

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, HStack, Stack, useToast } from '@chakra-ui/react';
import { FFRoles, LessonStatusTypes, SchoolDto, SessionLessonPlanDto, UserDto } from '@edanalytics/ff_be_se';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { atom, useAtom, useAtomValue } from 'jotai';
import { LessonPlanStatus } from './LessonPlan/LessonPlanStatus';
import { DebugCard } from './DebugCard';
import {
  CreateLessonLinkUrl,
  GradeLevelLabel,
  ObservationLink,
  ReadingLevelLabel,
  SchoolUserLink,
  ShortFormatDateTime,
  StudentLink,
  TutorLink,
  LessonPlanSessionCardStatus,
  SlugFormatTime,
  LessonPlanStartEndTime,
  MakeUpLessonPlanLink,
} from './Utils';
import { LessonPlanService, LessonPlanServiceAtoms } from '../services/LessonPlan/LessonPlanService';
import { ConfirmAlert } from './ConfirmationDialog';
import { SchoolServiceAtoms } from '../services/School';
import { userInfoAtom } from '../ffApi';
import { persistNavAtom } from '../atoms/navAtom';

export interface ISessionCardProps {
  lessonPlan: SessionLessonPlanDto;
  showObserveLinks?: boolean;
}

const deleteLessonPlanAtom = atom(null, async (get, set, newValue: { schoolId: number; studentId: number; lessonPlanId: number }) => {
  await get(LessonPlanService).deleteLessonPlan(newValue.schoolId, newValue.studentId, newValue.lessonPlanId);
  set(LessonPlanServiceAtoms.getScheduledSchoolLessonPlans, RESET);
  set(LessonPlanServiceAtoms.getTutorLessonPlans, RESET);
  set(LessonPlanServiceAtoms.getScheduledTutorLessonPlans, RESET);
});

export const SessionCard: React.FunctionComponent<ISessionCardProps> = ({ lessonPlan, showObserveLinks }) => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const navValue = useAtomValue(persistNavAtom);
  const location = useLocation();
  const nav = useNavigate();
  const [deleting, setDeleting] = useState<boolean>(false);
  const deleteLessonPlan = useUpdateAtom(deleteLessonPlanAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const toast = useToast();

  const performDelete = async () => {
    await deleteLessonPlan({ schoolId: school?.id as number, studentId: lessonPlan?.student?.id as number, lessonPlanId: lessonPlan.id });
    setDeleting(false);
    toast({ description: 'Lesson plan deleted', status: 'success' });
    if (navValue.lessonPlanId && location.pathname.indexOf('observe'))
      nav(`/schools/${navValue.schoolId}/tutors/${navValue.tutorId}/observe`);
  };

  return (
    <Box p={5} marginTop="1em" bg="white" shadow="md" borderWidth="1px" fontSize=".8em" rounded={8}>
      <Flex>
        <Box flex={2} w="10%">
          <Stack>
            {lessonPlan.student && (
              <Box>
                {StudentLink(lessonPlan.student)}
                <br />
                Grade {GradeLevelLabel(lessonPlan.student.gradeLevel)} - Reading Level {ReadingLevelLabel(lessonPlan.student.readingLevel)}
              </Box>
            )}
          </Stack>
        </Box>
        <Box flex={2} w="50%">
          <Box>
            {LessonPlanStartEndTime(lessonPlan)}

            {lessonPlan.isMakeUp && (
              <Box display={'inline'} ml={'1em'}>
                ({MakeUpLessonPlanLink(lessonPlan)})
              </Box>
            )}
          </Box>
          <Stack direction="row" spacing={20} align="right">
            {userInfo.roleId !== FFRoles.Tutor && lessonPlan.byUser && (
              <Box>
                {lessonPlan.byUser?.roleId === FFRoles.Tutor
                  ? TutorLink(school as SchoolDto, lessonPlan.byUser as UserDto)
                  : SchoolUserLink(school as SchoolDto, lessonPlan.byUser as UserDto)}
              </Box>
            )}
            {showObserveLinks && !!school && !!lessonPlan.byUser && <Box>{ObservationLink(lessonPlan, school, lessonPlan.byUser)}</Box>}
          </Stack>
        </Box>
        <Box w="30%">
          <HStack spacing={5} align="right">
            <Box>
              <Button width="210px" variant={'outline'} onClick={() => nav(CreateLessonLinkUrl(lessonPlan))}>
                {LessonPlanSessionCardStatus(lessonPlan.lessonStatus)}
                <span className="debug-hide dim">&nbsp;(#{lessonPlan.id})</span>
              </Button>
            </Box>
            <LessonPlanStatus lessonStatus={lessonPlan.lessonStatus} attendanceStatus={lessonPlan.attendanceStatus} />
            {lessonPlan.lessonStatus !== LessonStatusTypes.Scheduled && (
              <>
                <DeleteIcon fontSize="md" color="gray" onClick={() => setDeleting(true)} cursor="pointer" />
                <ConfirmAlert
                  active={deleting}
                  headerText="Delete Lesson Plan"
                  bodyText="Are you sure? You can't undo this action afterwards."
                  okHandle={performDelete}
                  cancelHandle={() => setDeleting(false)}
                  continueText="Delete"
                />
              </>
            )}
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};
