/* eslint-disable no-console */
import { Privilege, Roles } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { storedUserInfoAtom } from '../ffApi';

interface Props {
  noRedirect?: boolean;
  children: any;
  roles?: Roles[];
  privileges?: Privilege[];
}

export const FfGuardRedirect: React.FC<Props> = (props) => {
  const { roles = [] } = props;
  const [user] = useAtom(storedUserInfoAtom);

  let redirectUrl: undefined | string;

  if (!user) {
    console.info('FfGuard: No user redirect to /unauthorized', roles);
    redirectUrl = '/unauthorized';
  }

  if (!user?.active) {
    console.info('FfGuard: Not active user redirect to /inactive', roles);
    redirectUrl = '/inactive';
  }

  if (roles.length && !roles.includes(user.role!)) {
    console.info('FfGuard: Role mismatch user redirect to /unauthorized', { userRole: user.role, roles });
    redirectUrl = '/unauthorized';
  }

  useEffect(() => {
    console.info('FfGuard: authorized', roles);
  }, []);

  if (redirectUrl) return <Navigate to={redirectUrl} replace={true} />;

  return props.children;
};

export const FfGuard: React.FC<Props> = (props) => {
  if (!(props.noRedirect ?? false)) return <FfGuardRedirect {...props} />;

  const { roles = [] } = props;
  const [user] = useAtom(storedUserInfoAtom);

  if (!user) {
    return null;
  }

  if (!user.active) {
    return null;
  }

  if (roles.length && !roles.includes(user.role!)) {
    return null;
  }

  return props.children;
};
