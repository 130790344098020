import { Flex, HStack } from '@chakra-ui/react';
import { AttendanceStatusTypes } from '@edanalytics/ff_be_se';
import React from 'react';
import {
  AttendanceAttendedIcon,
  AttendanceStudentAbsenceIcon,
  AttendanceTutorAbsenceIcon,
  AttendanceEventAbsenceIcon,
  AttendanceHolidayIcon,
  NoAttendanceRecord,
} from './AttendanceRecordChartKey';

export interface AttendanceRecordDayEditProps {
  statusChange: (status: AttendanceStatusTypes) => void;
}

export const AttendanceRecordDayEdit: React.FunctionComponent<AttendanceRecordDayEditProps> = (props: AttendanceRecordDayEditProps) => {
  const [selectedStatus, setSelectedStatus] = React.useState('');
  const handleStatusChange = (status: AttendanceStatusTypes) => {
    props.statusChange(status);
    setSelectedStatus(status.toString());
  };
  return (
    <HStack fontSize={12} justify="center">
      <Flex
        bg={'white'}
        w="20px"
        h="20px"
        alignItems="center"
        justifyContent="center"
        border={`1px solid black`}
        shadow={`${selectedStatus === AttendanceStatusTypes.Attended.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
        onClick={() => handleStatusChange(AttendanceStatusTypes.Attended)}
      >
        <AttendanceAttendedIcon />
      </Flex>
      <Flex
        bg={'white'}
        w="20px"
        h="20px"
        alignItems="center"
        justifyContent="center"
        border={`1px solid black`}
        shadow={`${selectedStatus === AttendanceStatusTypes.StudentAbsence.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
        onClick={() => handleStatusChange(AttendanceStatusTypes.StudentAbsence)}
      >
        <AttendanceStudentAbsenceIcon />
      </Flex>
      <Flex
        bg={'white'}
        w="20px"
        h="20px"
        alignItems="center"
        justifyContent="center"
        border={`1px solid black`}
        shadow={`${selectedStatus === AttendanceStatusTypes.TutorAbsence.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
        onClick={() => handleStatusChange(AttendanceStatusTypes.TutorAbsence)}
      >
        <AttendanceTutorAbsenceIcon />
      </Flex>
      <Flex
        bg={'white'}
        w="20px"
        h="20px"
        alignItems="center"
        justifyContent="center"
        border={`1px solid black`}
        shadow={`${selectedStatus === AttendanceStatusTypes.EventAbsence.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
        onClick={() => handleStatusChange(AttendanceStatusTypes.EventAbsence)}
      >
        <AttendanceEventAbsenceIcon />
      </Flex>
      <Flex
        bg={'white'}
        w="20px"
        h="20px"
        alignItems="center"
        justifyContent="center"
        border={`1px solid black`}
        shadow={`${selectedStatus === AttendanceStatusTypes.Holiday.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
        onClick={() => handleStatusChange(AttendanceStatusTypes.Holiday)}
      >
        <AttendanceHolidayIcon />
      </Flex>
      <Flex
        bg={'white'}
        w="20px"
        h="20px"
        alignItems="center"
        justifyContent="center"
        border={`1px solid black`}
        shadow={`${selectedStatus === AttendanceStatusTypes.Scheduled.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
        onClick={() => handleStatusChange(AttendanceStatusTypes.Scheduled)}
      >
        <NoAttendanceRecord />
      </Flex>
    </HStack>
  );
};
