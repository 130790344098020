import React from 'react';
import { Box, forwardRef, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Flex, useToast } from '@chakra-ui/react';
import {
  AttendanceStatusTypes,
  PatchLessonPlanDto,
  SessionLessonPlanDto,
  StudentDto,
  StudentEnrollmentDto,
  LessonStatusTypes,
} from '@edanalytics/ff_be_se';
import { DateTime as DT } from 'luxon';
import { atom, useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import {
  AttendanceAttendedIcon,
  AttendanceAttendedSubmittedIcon,
  AttendanceEventAbsenceIcon,
  AttendanceHolidayIcon,
  AttendanceStudentAbsenceIcon,
  AttendanceTutorAbsenceIcon,
  NoAttendanceRecord,
} from './AttendanceRecordChartKey';
import { AttendanceRecordDayEdit } from './AttendanceRecordDayEdit';
import { FfRouterLink as Link } from '../../components/FfRouterLink';
import { atomApiWithNavAndUpdate } from '../../utils/async-atom';
import { LessonPlanService, LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { StudentServiceAtoms } from '../../services/Student';

export interface AttendanceRecordDayProps {
  index: number;
  month: string;
  session?: SessionLessonPlanDto;
  student?: StudentDto;
  enrollment?: StudentEnrollmentDto[];
}

const isValid = (date: Date, position: number) => DT.fromJSDate(date).isValid && DT.fromJSDate(date).day === position;

const isWeekend = (date: Date) => DT.fromJSDate(date).weekday === 6 || DT.fromJSDate(date).weekday === 7;

const isEnrollmentValid = (enrollments?: StudentEnrollmentDto[], boxDate?: any) => {
  if (enrollments) {
    for (let i = 0; i < enrollments.length; i += 1) {
      const enrollment = enrollments[i];
      if (
        enrollment.entryDate &&
        boxDate > enrollment.entryDate.setDate(enrollment.entryDate.getDate()) - 1 &&
        (enrollment.exitDate === null || (enrollment.exitDate && boxDate < enrollment.exitDate.setDate(enrollment.exitDate.getDate()) + 1))
      ) {
        return true;
      }
    }
    return false;
  }
  return false;
};

const getDateBoxBgColor = (
  sessionExists: boolean,
  attendanceExists: boolean,
  date: Date,
  position: number,
  enrollment?: StudentEnrollmentDto[],
) => {
  let color = 'white';
  if (sessionExists && attendanceExists === false) color = 'magenta';
  if (isWeekend(date)) color = 'lightgray';
  if (!isValid(date, position) || !isEnrollmentValid(enrollment, date)) color = 'transparent';
  return color;
};

const AttendanceStatusBox = forwardRef((props, ref) => {
  const getAttendanceIcon = (session?: any) => {
    let result: JSX.Element;
    if (!session) {
      return <></>;
    }
    let boolNoAttn = false;
    switch (session.attendanceStatus) {
      case AttendanceStatusTypes.Attended:
        result = <AttendanceAttendedIcon />;
        break;
      case AttendanceStatusTypes.StudentAbsence:
        result = <AttendanceStudentAbsenceIcon />;
        break;
      case AttendanceStatusTypes.TutorAbsence:
        result = <AttendanceTutorAbsenceIcon />;
        break;
      case AttendanceStatusTypes.EventAbsence:
        result = <AttendanceEventAbsenceIcon />;
        break;
      case AttendanceStatusTypes.Holiday:
        result = <AttendanceHolidayIcon />;
        break;
      default:
        result = <NoAttendanceRecord />;
        boolNoAttn = true;
        break;
    }

    if (session.lessonStatus === LessonStatusTypes.Submitted && session.attendanceStatus === AttendanceStatusTypes.Attended) {
      result = <AttendanceAttendedSubmittedIcon />;
    }

    return <div style={{ padding: boolNoAttn ? `0em` : `.2em`, margin: '-.7em' }}>{result}</div>;
  };
  return (
    <Box>
      <div ref={ref}>{getAttendanceIcon(props.session)}</div>
    </Box>
  );
});

export type AttendanceChanges = { [key: number]: number };

export const attendanceEditAtom = atom<boolean>(false);

export const patchSessionAtom = atomApiWithNavAndUpdate(
  () => Promise.resolve(undefined),
  async (get, set, { id, attendanceStatus }: { id: number; attendanceStatus: number }, nav) => {
    if (!nav.schoolId || !nav.studentId) return Promise.resolve(undefined);
    await get(LessonPlanService).patchLessonPlan(nav.schoolId, nav.studentId, id, {
      id,
      attendanceStatus,
    } as PatchLessonPlanDto);
    set(LessonPlanServiceAtoms.getStudentLessonPlansAttendanceChart, RESET);
    return Promise.resolve();
  },
);

export const AttendanceRecordDay: React.FunctionComponent<AttendanceRecordDayProps> = (props: AttendanceRecordDayProps) => {
  const enrollment = useAtomValue(StudentServiceAtoms.getCurrentStudentEnrollment);
  const today = DT.fromJSDate(DT.fromFormat(props.month, 'yyyy-MM').toJSDate()).plus({
    days: props.index,
  });

  const [edit] = useAtom(attendanceEditAtom);
  const [, patchSession] = useAtom(patchSessionAtom);
  const toast = useToast();

  return (
    <Flex
      key={props.index}
      bg={getDateBoxBgColor(
        props.session !== undefined,
        props.session?.attendanceStatus !== null,
        today.toJSDate(),
        props.index + 1,
        enrollment,
      )}
      w="20px"
      h="20px"
      border={props.session?.isMakeUp ? '2px solid' : '0px'}
      borderColor={props.session?.isMakeUp ? 'ff.teal' : ''}
      alignItems="center"
      justifyContent="center"
      position="relative"
      cursor={edit && props.session ? 'pointer' : ''}
    >
      {edit === true && isEnrollmentValid(enrollment, today.toJSDate()) && isValid(today.toJSDate(), props.index + 1) && (
        <Popover placement="bottom">
          <PopoverTrigger>
            <Box>
              <PopoverTrigger>
                <AttendanceStatusBox session={props.session} />
              </PopoverTrigger>
            </Box>
          </PopoverTrigger>
          <PopoverContent w="200px" color="white" bg="white">
            <PopoverArrow bg="white" border="1px solid" />
            <PopoverBody textAlign="center">
              <AttendanceRecordDayEdit
                statusChange={async (attendanceStatus: number) => {
                  await patchSession({ id: props.session?.id!, attendanceStatus });
                  toast({ description: 'Attendance record saved', status: 'success' });
                }}
              />
              <Link
                fontSize="medium"
                to={`/schools/${props.student?.schoolId}/students/${props.student?.id}/lesson-plans/${props.session?.id}`}
              >
                Go To Lesson Plan
              </Link>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {edit === false && isEnrollmentValid(enrollment, today.toJSDate()) && isValid(today.toJSDate(), props.index + 1) && (
        <AttendanceStatusBox session={props.session} />
      )}
    </Flex>
  );
};
