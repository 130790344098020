/* eslint-disable no-console */
import { useAtom } from 'jotai';
import React from 'react';
import { storedDistrictsAtom } from '../../atoms/districtAtom';
import { DistrictService } from '../../services/District';

export const PreloadDistricts = ({ children }: any) => {
  // set the stored districts on load
  const [, setStoredDistricts] = useAtom(storedDistrictsAtom);

  const [service] = useAtom(DistrictService);
  // bootstrap the districts
  React.useEffect(() => {
    service.getAllDistricts().then((r) => setStoredDistricts(r));
  }, []);

  return <>{children}</>;
};
