import { Box, Container, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import { useAtom } from 'jotai';
import { UserServiceAtoms } from '../../services/User';
import { FfDivider } from '../../components/FfDivider';
import { DebugCard } from '../../components/DebugCard';
import { DistrictServiceAtoms } from '../../services/District';
import { EditUserForm } from '../../components/EditUserForm';

export const EditUserScreen: React.FunctionComponent = () => {
  const [user] = useAtom(UserServiceAtoms.getCurrentStaff);
  const [districts] = useAtom(DistrictServiceAtoms.getAllDistricts);

  return (
    <Container maxWidth="100%">
      <Heading>Edit User</Heading>
      {user && (
        <Stack>
          <FfDivider />
          <Box>
            <EditUserForm user={user} districts={districts} />
          </Box>
        </Stack>
      )}
      <DebugCard data={[{ name: 'user', data: user }]} />
    </Container>
  );
};
