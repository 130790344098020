/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { Suspense } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import {
  FamilyInteractionAttemptDto,
  FamilyInteractionDto,
  FamilyInteractionTypes,
  PutFamilyInteractionAttemptDto,
  StudentDto,
  StudentEnrollmentDto,
} from '@edanalytics/ff_be_se';
import { DateTime as DT } from 'luxon';
import { atom, useAtomValue } from 'jotai';
import { isNull, isNumber, isUndefined } from 'lodash';
import { FormikProps, useFormikContext } from 'formik';
import { FAMILY_EVENT_INTERVAL_MINS } from '../../config/constants';
import { FfFormikDateTimePickerJon } from '../Formik/FfFormikDateTimePickerJon';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { familyInteractionTypeOptions, InformationShared, InteractionSuccess } from './FamilyInteractionModel';
import { atomApiWithNavAndRead, atomApiWithNavAndUpdate, atomApiWithRead } from '../../utils/async-atom';
import { StudentService } from '../../services/Student';
import { Loader } from '../Loader';
import { TimePicker } from '../TimePicker';
import { FfFormikDateTimePicker } from '../Formik/FfFormikDateTimePicker';
import { getDateExclusions } from '../Utils';

export const studentIdAtom = atom(undefined as number | undefined);

export const studentAtom = atomApiWithNavAndUpdate(
  (get, nav) => {
    const schoolId = nav?.schoolId;
    const studentId = get(studentIdAtom);
    if (!schoolId || !studentId) return Promise.resolve(undefined);

    return get(StudentService).getStudent(schoolId, studentId);
  },
  (get, set, newValue: number | StudentDto, nav) => {
    if (isNumber(newValue)) {
      set(studentIdAtom, newValue);
      return Promise.resolve(undefined);
    }
    return Promise.resolve(newValue);
  },
);

// studentIdAtom is only used in this file, otherwise use StudentServiceAtoms.getCurrentStudentEnrollment
export const studentEnrollmentsAtom = atomApiWithNavAndRead((get, nav) => {
  const schoolId = nav?.schoolId;
  const studentId = nav?.studentId;
  if (!schoolId || !studentId) return Promise.resolve(undefined);

  return get(StudentService).getStudentEnrollment(schoolId, studentId);
});

type FamilyInteractionProps = FormikProps<FamilyInteractionDto>;

export const GenericInteractionFormInternal: React.FunctionComponent<{
  isLoading: boolean;
  isSubmitted: boolean;
  attemptValues: FamilyInteractionAttemptDto | PutFamilyInteractionAttemptDto;
  index: number;
}> = ({ isLoading, isSubmitted, attemptValues, index }) => {
  const initInteractionTime = DT.fromJSDate(attemptValues.interactionDate).toFormat('HH:mm');

  const { values, submitForm } = useFormikContext();
  const [interactionTime, setInteractionTime] = React.useState(initInteractionTime);
  const formik = useFormikContext<FamilyInteractionDto>();

  const student = useAtomValue(studentAtom) as undefined | StudentDto;
  const contacts = student?.studentContacts?.map((c) => ({ fullName: `${c.lastName} ${c.firstName}`, ...c }));
  const primaryContact = contacts?.find((c) => c.primary)?.fullName ?? '';
  const secondaryContact = contacts?.find((c) => !c.primary)?.fullName ?? '';

  const contactTypes = [
    !!primaryContact && { key: 'Primary Contact', value: '1' },
    !!secondaryContact && { key: 'Secondary Contact', value: '2' },
    { key: 'Other', value: '3' },
  ].filter((row) => row) as { key: string; value: string }[];

  const enrollments = useAtomValue(studentEnrollmentsAtom);
  const exclusions = getDateExclusions(enrollments);

  const updateEndDate = (date?: string) => {
    if (!date) return;
    const datePortion = DT.fromJSDate(attemptValues.interactionDate).toFormat('yyyy-MM-dd');
    const newInteractionTime = DT.fromISO(date).toFormat('HH:mm');
    const newInteractionDate = DT.fromFormat(`${datePortion} ${newInteractionTime}`, 'yyyy-MM-dd HH:mm').toJSDate();
    setInteractionTime(newInteractionTime);
    formik.setFieldValue(`attempts.${index}.interactionDate`, newInteractionDate);
  };

  return (
    <Flex pt="15px">
      <Box w="48%">
        {/* WIP
        <FfFormikDateTimePickerJon
          id={`attempts.${index}.interactionDate`}
          label="Interaction Date"
          timePickerLabel="Interaction Time"
          isDisabled={isLoading || isSubmitted || !enrollments}
          filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
          timeIntervals={FAMILY_EVENT_INTERVAL_MINS}
        />
            */}
        <FfFormikDateTimePicker
          id={`attempts.${index}.interactionDate`}
          label="Interaction Date"
          isDisabled={isLoading || isSubmitted}
          timeIntervals={FAMILY_EVENT_INTERVAL_MINS}
          dateFormat="MM/dd/yyyy"
          excludeDateIntervals={exclusions}
        />
        <TimePicker
          id={`attempts.${index}.interactionTimeOnly`}
          startTime={interactionTime}
          format="HH:mm"
          label={'Interaction Time'}
          onChange={(d) => updateEndDate(d.startTime)}
        />
        {/* onError={(e) => setHasError(`${studentId}:${index}`, !!e)} */}
        {contactTypes.length > 1 && (
          <FfFormikSelect
            id={`attempts.${index}.contactNameSelectOnly`}
            label={`Contact Type`}
            options={contactTypes}
            isDisabled={student === undefined}
            onChange={(opt, form: FamilyInteractionProps) => {
              form.setFieldValue(
                `attempts.${index}.contactNameSelect`,
                (attemptValues.contactName = opt?.value === '1' ? primaryContact : opt?.value === '2' ? secondaryContact : ''),
              );
              return true; // Override default handler
            }}
          />
        )}

        <FfFormikInput id={`attempts.${index}.contactName`} label={`Contact Name`} type="string" />

        <FfFormikSelect
          id={`attempts.${index}.interactionType`}
          label={`Type of Interaction`}
          options={familyInteractionTypeOptions}
          // isDisabled={}
          onChange={(opt, form: FamilyInteractionProps) => {
            form.setFieldValue(`attempts.${index}.interactionType`, opt?.value ?? '0');
            form.setFieldValue(
              `attempts.${index}.json`,
              FamilyInteractionTypes.HomeVisit.toString() === opt?.key ? new InformationShared() : {},
            );
            return true;
          }}
        />

        <Box hidden={attemptValues.interactionType !== FamilyInteractionTypes.HomeVisit}>
          <FfFormikInput id={`attempts.${index}.homeVisitAddress`} label={`Home Address`} type="string" />
        </Box>

        <FfFormikSelect
          id={`attempts.${index}.isSuccessful`}
          label={`Interaction Success?`}
          options={InteractionSuccess}
          value={isNull(attemptValues.isSuccessful) ? undefined : attemptValues.isSuccessful ? '1' : '2'}
          // isDisabled={}
          onChange={(opt, form: FamilyInteractionProps) => {
            form.setFieldValue(`attempts.${index}.isSuccessful`, isUndefined(opt) ? null : opt.value === '1');
            return true; // Override default handler
          }}
        />
      </Box>
      <Spacer></Spacer>
      <Box w="48%">
        <FfFormikTextarea
          id={`attempts.${index}.notes`}
          noOfLines={10}
          label="Interaction Notes (Internal)"
          bgColor="white"
          borderColor="ff.pink !important"
          border="2px"
          h="150px"
          value={attemptValues.notes}
        />
      </Box>
    </Flex>
  );
};

export const GenericInteractionForm: typeof GenericInteractionFormInternal = (props) => (
  <Suspense fallback={<Loader />}>
    <GenericInteractionFormInternal {...props} />
  </Suspense>
);
