/* eslint-disable no-alert */
import { Box, Button, ButtonGroup, Container, Flex, Heading, Spacer, useToast } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { PutStudentTutorAssignmentDto, PutTutorAssignmentDto } from '@edanalytics/ff_be_se';
import { validateSync } from 'class-validator';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { DebugCard } from '../../components/DebugCard';
import { StudentService } from '../../services/Student';
import { SchoolServiceAtoms } from '../../services/School';
import { TutorStudentAssignment } from '../../components/TutorStudentAssignment/TutorStudentAssignment';
import { FfDivider } from '../../components/FfDivider';
import { navAtom, REFRESH } from '../../atoms/navAtom';
import { Loader } from '../../components/Loader';
import { delayMs } from '../../utils/utils';
import {
  AnyTutorAssignmentHasErrorAtom,
  appointmentsAtom,
  TutorAssignmentIsSavedAtom,
} from '../../components/TutorStudentAssignment/useScheduleFormArray';

export const TutorAssignmentScreen: React.FunctionComponent = () => {
  const setUpdates = useUpdateAtom(appointmentsAtom);
  const [isSaved, setIsSaved] = useAtom(TutorAssignmentIsSavedAtom);
  const hasError = useAtomValue(AnyTutorAssignmentHasErrorAtom);
  const [isPending, setIsPending] = React.useState(false);
  const [service] = useAtom(StudentService);
  const setNav = useUpdateAtom(navAtom);
  const appointments = useAtomValue(appointmentsAtom);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const toast = useToast();

  const doValidateOnUpdates = (sta: PutStudentTutorAssignmentDto[]) => {
    const validatePutTutorAssignment = (pta: PutTutorAssignmentDto) => {
      const ptaErrors = validateSync(Object.assign(new PutTutorAssignmentDto(), pta));
      if (ptaErrors && ptaErrors.length) {
        alert(`validation failed! check your input: ${JSON.stringify(ptaErrors, null, 2)}`);
        throw new Error('validation failed');
      }
    };
    for (let index = 0; index < sta.length; index += 1) {
      const l = sta[index];
      // validate the outer object
      const pstaErrors = validateSync(Object.assign(new PutStudentTutorAssignmentDto(), l));
      if (pstaErrors && pstaErrors.length) {
        alert(`validation failed! check your input: ${JSON.stringify(pstaErrors, null, 2)}`);
        throw new Error('validation failed');
      }
      // validate all inner put tutor assignments
      l.tutorAssignments.forEach((pta) => validatePutTutorAssignment(pta));
    }
  };

  const doSaveUpdates = async (studentTutorAssignments: PutStudentTutorAssignmentDto[]) => {
    setIsPending(true);
    await Promise.all([
      service.putTutorAssignments(school?.id as number, studentTutorAssignments),
      delayMs(1000), // ensure spinner visibility
    ]);
    toast({ description: 'Tutor assignment saved', status: 'success' });
  };

  const saveUserUpdates = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _ = await Promise.resolve(1);
    if (hasError) {
      // we won't reject here
      return;
    }

    try {
      const studentTutorAssignments: PutStudentTutorAssignmentDto[] = [];
      Object.keys(appointments).forEach((studentId) => {
        const psta = new PutStudentTutorAssignmentDto();
        psta.studentId = Number(studentId);
        psta.tutorAssignments = appointments[studentId].map((a) => {
          const pta = new PutTutorAssignmentDto();
          pta.tutorId = a.tutorId as number;
          pta.dayOfWeek = a.dayOfWeek as number;
          pta.startTime = a.startTime as string;
          pta.endTime = a.endTime as string;
          return pta;
        });
        studentTutorAssignments.push(psta);
      });
      doValidateOnUpdates(studentTutorAssignments);
      await doSaveUpdates(studentTutorAssignments);
      setIsSaved(true);
      setNav(REFRESH);
    } catch (error) {
      // likely a validation error
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setIsPending(false);
  };

  const Cancel = () => {
    setUpdates(RESET);
  };

  return (
    <Container maxWidth="100%">
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Box p="2">
          <Heading color={'ff.blue'}>Assign Tutors</Heading>
          <Box>Drag and drop tutors into student schedules</Box>
        </Box>
        <Spacer />
        <ButtonGroup gap="1">
          <Button onClick={Cancel}>Cancel</Button>
          <Button disabled={isSaved} isLoading={isPending} onClick={saveUserUpdates}>
            Save{isSaved ? 'd' : ''}
          </Button>
        </ButtonGroup>
      </Flex>
      <FfDivider />
      <Suspense fallback={<Loader />}>
        <TutorStudentAssignment schoolId={school?.id as number} />
        <DebugCard data={[{ name: 'school', data: school }]} />
      </Suspense>
    </Container>
  );
};
