/* eslint-disable no-nested-ternary */
import { Box, chakra, Flex, Heading, Img, Text, useTheme } from '@chakra-ui/react';
import { LessonPlanModuleDto } from '@edanalytics/ff_be_se/dist/models/LessonPlanModule';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import ReactToPrint from 'react-to-print';
import FutureForwardLogoDark from '../../assets/images/FutureForwardLogoDark.svg';
import { LessonPlanPrintableModuleConfig } from '../../components/LessonPlanContent/LessonPlanContentConfig';
import { GenericModuleData } from '../../components/LessonPlanContent/models/genericLessonModule';
import { LessonPlanModuleTypes } from '../../components/LessonPlanContent/models/LessonPlanModuleEnum';
import { getLessonPlanTypeString, LessonPlanTypes } from '../../components/LessonPlanContent/models/LessonPlanTypeEnum';
import { PrintableInputs } from '../../components/PrintableInputs';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { UserServiceAtoms } from '../../services/User';
import { secondsToDurationString } from '../../utils/utils';
import { CompactFormatDate, CompactFormatDateString, SlugFormatTime } from '../../components/Utils';

export const GenericPrintableModule = (data: GenericModuleData) => {
  const PrintableComponent = LessonPlanPrintableModuleConfig[data.moduleKey as LessonPlanModuleTypes][data.moduleVersion];
  if (PrintableComponent) {
    return <PrintableComponent data={data} />;
  }
  return (
    <Box>
      "{data.moduleKey}" v{data.moduleVersion} printable module not found
    </Box>
  );
};

const PrintableLessonPlanModule = (props: { module: LessonPlanModuleDto }) => {
  const data = props.module.json;
  return (
    <tr>
      <td>
        <Heading fontSize="md">{data.moduleName}</Heading>
        {secondsToDurationString(data.moduleDurationSecs, {
          fontSize: '0.75em',
          ml: '0',
          fontWeight: 'bold',
        })}
      </td>
      <td>
        <GenericPrintableModule {...data} />
      </td>
    </tr>
  );
};

/**
 * Print the current lesson plan to a print-specific layout.
 *
 * @param props.children Pass in a button or other clickable element. React-to-print will use this as the trigger (no need for you to provide an onClick).
 * @returns Wraps your trigger.
 */
export const LessonPlanPrint = (props: { children?: JSX.Element }) => {
  const [lessonPlan] = useAtom(LessonPlanServiceAtoms.getUpdateLessonPlan);
  const [users] = useAtom(UserServiceAtoms.getSchoolUsers);
  const theme = useTheme();

  const button = props.children;
  if (!lessonPlan || button === undefined || !users) return <></>;

  const printComponentRef = React.useRef<HTMLTableElement>(null);

  return (
    <>
      <ReactToPrint trigger={() => button} content={() => printComponentRef.current} />
      <chakra.table
        ref={printComponentRef}
        fontSize="0.9em"
        css={{
          display: 'none',
          '& td': {
            borderBottomWidth: '2px',
            borderBottomColor: theme.colors.ff.magenta,
            paddingTop: '0.25em',
            textAlign: 'left',
          },
          '& td:first-of-type': {
            verticalAlign: 'top',
          },
          '@media print': {
            '&': {
              display: 'block',
            },
          },
          '@page': {
            margin: '0.25in',
          },
        }}
      >
        <tr>
          <chakra.td minW="1.2in">
            <Img src={FutureForwardLogoDark} />
          </chakra.td>
          <chakra.td w="100%">
            <Box ml="1em">
              <Heading mb="0.35em" textAlign="left" fontSize={'2xl'}>
                Lesson Plan
                <Text as="span" color={'ff.magenta'} ml="1em" fontSize="1rem" css={{ textTransform: 'uppercase' }}>
                  <Text as="span" fontSize="0.75em">
                    {getLessonPlanTypeString(lessonPlan?.lessonPlanType)}
                  </Text>
                </Text>
              </Heading>
            </Box>
            <Flex direction="row" flexWrap="nowrap" justify="start">
              <PrintableInputs.Input minWidth={17} label="Student Name" children={lessonPlan.student?.fullName} />
              {/* WIP end time ?? */}

              <PrintableInputs.Input minWidth={10} label="Lesson Date" children={CompactFormatDateString(lessonPlan.startDateTime)} />
              <PrintableInputs.Input
                minWidth={10}
                label="Lesson Time"
                children={`${SlugFormatTime(lessonPlan.startDateTime)} - ${SlugFormatTime(lessonPlan.endDateTime)}`}
              />
            </Flex>
            <Flex direction="row" flexWrap="wrap" justify="start">
              <PrintableInputs.Input
                css={{ flexGrow: 1, maxWidth: '35em' }}
                label="Tutor Name"
                minWidth={20}
                children={users.find((user) => user.id === lessonPlan.byUserId)?.fullName}
              />
            </Flex>
          </chakra.td>
        </tr>
        {_.sortBy(lessonPlan.lessonPlanModules, (module) => module.lessonContentCode).map((module, index) => (
          <PrintableLessonPlanModule key={index} module={module} />
        ))}
        <tr>
          <td />
          <td>
            <PrintableInputs.Textarea
              label="Additional Notes"
              minLines={lessonPlan.lessonPlanType === LessonPlanTypes.EarlyReader ? 5 : 3}
            />
          </td>
        </tr>
        <tr>
          <chakra.td border="none" colSpan={2}>
            {lessonPlan.lessonPlanType === LessonPlanTypes.EarlyReader
              ? 'Note: If no Running Record, add 2 minutes to Instructional Reading and Writing.'
              : 'Note: If no Bi-Weekly Assessment, complete Writing section.'}
          </chakra.td>
        </tr>
      </chakra.table>
    </>
  );
};
