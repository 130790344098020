/* eslint-disable no-nested-ternary */
import { Box, StyleProps, useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import { isNull, isNumber, isUndefined, isNaN as lisNaN, toNumber } from 'lodash';
import { DateTime as DT } from 'luxon';

export const padNumber = (num: number, length: number) =>
  num.toLocaleString('en-US', {
    minimumIntegerDigits: length,
  });

export const getFormattedDate = (dtString: string, format: string) => DT.fromISO(dtString).toFormat(format);

export function getStartTimeYearMonthKey<T>(getValue: (item: T) => string) {
  return (item: T) => DT.fromISO(getValue(item)).toFormat('yyyy-MM');
}

// Overload 1: Function that takes an object with a 'date' property
function getDateYearMonthKeyInt<T extends { [K in string]: any }, K extends keyof T>(item: T, dateProp?: K): string;
function getDateYearMonthKeyInt<T extends { date: string }, K extends 'date'>(item: T, dateProp: K = 'date' as K): string {
  return DT.fromISO('date' in item ? item.date : (item[dateProp] as string)).toFormat('yyyy-MM');
}

export const getDateYearMonthKey = getDateYearMonthKeyInt;

export const getAsDayMonthTime = (dateProp: any) => DT.fromISO(dateProp).toFormat('cccc, LLLL d, ha');
export const getAsYearMonthDay = (dateProp: any) => DT.fromISO(dateProp).toFormat('yyyy-MM-dd'); // Monday, June 10, 10AM

// eslint-disable-next-line no-promise-executor-return
export const delayMs = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

export const isEmpty = (obj: any) => obj === undefined || Object.keys(obj).length === 0;

export const toNum = (text: string | undefined | null) =>
  isUndefined(text) || isNull(text) ? undefined : lisNaN(+text) ? undefined : toNumber(text);

export const secondsToDurationString = (seconds: number, props?: StyleProps) => {
  let result = '';
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  if (mins) result = `${mins} MIN `;
  if (secs) result += `${secs} SEC`;

  return result ? (
    <Box display="inline-block" fontSize="sm" color={'ff.magenta'} ml="1em" {...props}>
      {result}
    </Box>
  ) : (
    ''
  );
};

export const getEnumKeys = (enumObject: any) => Object.values(enumObject).filter((v) => typeof v === 'number') as number[];

export const getOptions = <EType = any,>(enumeration: EType, textMap: { [key in keyof EType]: string }) =>
  Object.entries(textMap).map(([label, text]) => ({
    value: (enumeration as any)[label] as number,
    key: text as string,
  }));

export type EnumFuncType<En = {}> = (index: En) => string;

export const getEnumTextFunc = <EType = {},>(enumeration: EType, textMap: { [key in keyof EType]: string }, errorMsgObj?: string) => {
  const entries = Object.entries(textMap).map(([label, text]) => [(enumeration as any)[label], text]);
  const dict = Object.fromEntries(entries) as { [key in number]: string };
  return (index: number) => {
    const result = isNumber(index) ? dict[index as number] : undefined;
    if (result === undefined) {
      // eslint-disable-next-line no-console
      console.error(`Bad index value ${index} for ${errorMsgObj ?? 'unspecified object.'}`);
    }

    return result;
  };
};

export const setFieldValueDefaulted = <T = any,>(
  form: FormikProps<T>,
  fieldId: string,
  value: any,
  formDefault: any,
  dataDefault?: any,
) => {
  const isDefault = (value ?? formDefault) === dataDefault;
  form.setFieldValue(fieldId, isDefault ? dataDefault : value);
};

export const useFfErrorToast = () => {
  const toast = useToast();
  const func = (error: any) => {
    // eslint-disable-next-line no-console
    console.error(error);
    const message = error instanceof AxiosError ? error.response?.data.message : error instanceof Error ? error.message : 'Unknown Error';
    toast({ description: message, status: 'error' });
  };
  return func;
};
