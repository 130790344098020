import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { SessionLessonPlanDto, StudentDto, StudentEnrollmentDto } from '@edanalytics/ff_be_se';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import _, { isEmpty } from 'lodash';
import { DateTime as DT } from 'luxon';
import React, { Suspense, useEffect } from 'react';
import { getDateYearMonthKey, padNumber } from '../../utils/utils';
import { Empty } from '../Empty';
import { FfDivider } from '../FfDivider';
import { AttendanceRecordChartKey } from './AttendanceRecordChartKey';

import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { StudentServiceAtoms } from '../../services/Student';
import { Loader } from '../Loader';
import { calculateAttendanceScore } from '../Utils';
import { attendanceEditAtom, AttendanceRecordDay } from './AttendanceRecordDay';
import { AttendanceRecordEdit } from './AttendanceRecordEdit';

export interface IAttendanceRecordChartProps {
  student: StudentDto;
}

const DayNumberGridItems: React.FunctionComponent = () => (
  <>
    {_.times(31, (index: number) => (
      <Box key={index} w="20px" textAlign="center" fontSize="x-small">
        {padNumber(index + 1, 2)}
      </Box>
    ))}
  </>
);

function getStartExitDate(startDate: any, endDate: any) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const startMonth = `0${start.getMonth() + 1}`.slice(-2);
  const startYear = start.getFullYear();
  const startMonthYear = `${startYear}-${startMonth}`;

  let endMonthYear = null;
  const endMonth = `0${end.getMonth() + 1}`.slice(-2);
  const endYear = end.getFullYear();
  endMonthYear = `${endYear}-${endMonth}`;

  const startExit = { startMonthYear, endMonthYear };
  return startExit;
}

function setExitDateToRecentLessonPlanEndMonth(enrollments: StudentEnrollmentDto[], lessonPlans: SessionLessonPlanDto[]) {
  if (lessonPlans.length < 1) return [];
  // Sort lesson plans by date in descending order
  const orderedLessonPlans = lessonPlans.sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime());

  // Get the most recent lesson plan date
  const mostRecentDate = new Date(orderedLessonPlans[0].startDateTime);

  // Map through enrollments, creating a new array of updated enrollments with exitDates set to mostRecentDate where null
  const updatedEnrollments = enrollments.map((enrollment: any) =>
    !enrollment.exitDate
      ? { ...enrollment, exitDate: new Date(mostRecentDate.getFullYear(), mostRecentDate.getMonth() + 1, mostRecentDate.getDate() + 1) }
      : enrollment,
  );

  return updatedEnrollments;
}

export const AttendanceRecordChartContent: React.FC<IAttendanceRecordChartProps> = ({ student }) => {
  const resetStudentEnrollment = useResetAtom(StudentServiceAtoms.getCurrentStudentEnrollment);
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getStudentLessonPlansAttendanceChart);
  let enrollments = useAtomValue(StudentServiceAtoms.getCurrentStudentEnrollment);
  useEffect(() => {
    resetStudentEnrollment();
  }, []); // onload effect

  if (enrollments) {
    enrollments = setExitDateToRecentLessonPlanEndMonth(enrollments, lessonPlans);
  }
  // WIP
  const enrollmentDates = enrollments?.map((x: StudentEnrollmentDto) => getStartExitDate(x.entryDate, x.exitDate));

  const groupedLessonPlans: { [dateStr: string]: SessionLessonPlanDto[] } = _.groupBy(lessonPlans, (s) =>
    getDateYearMonthKey(s, 'startDateTime'),
  );

  const groupedLessonPlansByEnrollment = Object.keys(groupedLessonPlans)
    .filter((key) => enrollmentDates?.some((x) => key >= x.startMonthYear && key <= x.endMonthYear))
    .reduce((obj: any, key) => {
      obj[key] = groupedLessonPlans[key];
      return obj;
    }, {});

  const getSessionForDate = (date: Date) => {
    const result = lessonPlans.find(
      (x) => DT.fromISO(x.startDateTime).toFormat('yyyy-MM-dd') === date.toISOString().substring(0, 10) && x.student?.id === student.id,
    );
    return result;
  };

  const MonthRow = (rowIndex: number, monthKey: string, attendanceScore: string, firstDate: Date, studentData?: StudentDto) => (
    <Stack key={rowIndex} direction="row" marginBottom="5px" fontSize="12px">
      <Box textAlign="center" w="50px">
        {DT.fromFormat(monthKey, 'yyyy-MM').toFormat('MMM !yy').replace('!', "'")}
      </Box>
      <Box textAlign="right" w="50px">
        {attendanceScore}
      </Box>
      {_.times(31, (index: number) => {
        const today = DT.fromJSDate(firstDate).plus({ days: index });
        const matchedSession = getSessionForDate(today.toJSDate());
        return (
          <div key={index}>
            <AttendanceRecordDay student={studentData} index={index} month={monthKey} session={matchedSession} />
          </div>
        );
      })}
    </Stack>
  );

  return (
    <>
      {!isEmpty(groupedLessonPlansByEnrollment) && (
        <>
          <Stack>
            <Box>
              <Stack direction="row">
                <Box w="50px"></Box>
                <Box w="50px"></Box>
                <DayNumberGridItems />
              </Stack>
            </Box>
            <Box>
              {groupedLessonPlansByEnrollment &&
                Object.keys(groupedLessonPlansByEnrollment).map((monthKey, index) =>
                  MonthRow(
                    index,
                    monthKey,
                    calculateAttendanceScore(groupedLessonPlansByEnrollment[monthKey]).rate,
                    DT.fromFormat(monthKey, 'yyyy-MM').toJSDate(),
                    student,
                  ),
                )}
            </Box>
          </Stack>
          <FfDivider />
          <AttendanceRecordChartKey />
        </>
      )}
      {isEmpty(groupedLessonPlansByEnrollment) && <Empty description="No attendance records." />}
    </>
  );
};

export const AttendanceRecordChart: React.FC<IAttendanceRecordChartProps> = (props) => {
  const [, setEditing] = useAtom(attendanceEditAtom);

  return (
    <Stack mb="3em">
      <Flex>
        <Box>
          <Heading color={'ff.blue'}>
            Attendance Record <AttendanceRecordEdit editToggleCb={() => setEditing((prev) => !prev)} />
          </Heading>
        </Box>
      </Flex>
      <FfDivider />
      <Suspense fallback={<Loader />}>
        <AttendanceRecordChartContent {...props} />
      </Suspense>
    </Stack>
  );
};
