import { Box, Stack } from '@chakra-ui/react';
import { StudentDto } from '@edanalytics/ff_be_se';
import { getGradeLevelTypeString } from './LessonPlanContent/models/GradeLevelEnum';
import { StudentStatusLabel, StudentStatusLabelAsOfDate, ShortFormatDate, studentStatusColor } from './Utils';

export interface IStudentSlugProps {
  student: StudentDto;
}

export const StudentSlug: React.FunctionComponent<IStudentSlugProps> = ({ student }) => (
  <Stack isInline spacing={4} align="center" color={studentStatusColor(student)}>
    <Box>Grade {getGradeLevelTypeString(student.gradeLevel)}</Box>
    <Box>{student.teacher}</Box>
    <Box>
      {StudentStatusLabel(student.active)} (as of {StudentStatusLabelAsOfDate(student)}){' '}
    </Box>
  </Stack>
);
