/* eslint-disable no-nested-ternary */
/* eslint-disable max-classes-per-file */
import React, { useEffect, useRef } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  PostFamilyInteractionAttemptDto,
  PostFamilyInteractionDto,
  PutFamilyInteractionAttemptDto,
  PutFamilyInteractionDto,
  StudentDto,
} from '@edanalytics/ff_be_se';

import { Text, Box, Flex, Grid, GridItem, Heading, Link, Stack, useToast, HStack, Button } from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { FieldArray, Form, FormikProps, setNestedObjectValues } from 'formik';
import { isUndefined, merge, sortBy } from 'lodash';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { FamilyInteractionService as familyInteractionService, FamilyInteractionServiceAtoms } from '../../services/FamilyInteraction';
import { GenericInteractionForm, studentAtom, studentIdAtom } from './GenericInteractionForm';
import { HomeVisitForm } from './HomeVisitForm';
import { FecList, IcList } from './StaffUserLists';
import { StudentServiceAtoms } from '../../services/Student';
import { DebugCard } from '../DebugCard';
import { getRoundedDate } from '../Utils';
import { FAMILY_EVENT_INTERVAL_MINS } from '../../config/constants';
import { persistNavAtom } from '../../atoms/navAtom';
import { SchoolServiceAtoms } from '../../services/School';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { useFfErrorToast } from '../../utils/utils';

const FamilyInteractionAttemptFactory = () => {
  const res = new PostFamilyInteractionAttemptDto();
  res.interactionDate = getRoundedDate(FAMILY_EVENT_INTERVAL_MINS, new Date());
  res.json = {};
  return res;
};
const FamilyInteractionFactory = (studentId?: number) => {
  const res = new PostFamilyInteractionDto();
  res.studentId = studentId!;
  res.attempts = [FamilyInteractionAttemptFactory()];
  return res;
};

const PutFamilyInteractionFactory = ({ attempts, ...data }: PutFamilyInteractionDto) => {
  const res = merge(new PutFamilyInteractionDto(), data);
  res.attempts = attempts.map((a) => merge(new PutFamilyInteractionAttemptDto(), a));
  return res;
};

type InteractionDto = PostFamilyInteractionDto | PutFamilyInteractionDto;

export const FamilyInteractionForm: React.FunctionComponent<{}> = () => {
  const nav = useNavigate();
  const toast = useToast();
  const errToast = useFfErrorToast();
  const formRef = useRef<FormikProps<InteractionDto> | null>();
  const setStudentId = useUpdateAtom(studentIdAtom);
  const [navAtomProps] = useAtom(persistNavAtom);
  const [students] = useAtom(StudentServiceAtoms.getCurrentStudents);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [familyInteractions, setFamilyInteractions] = useAtom(FamilyInteractionServiceAtoms.getFamilyInteractionsById);
  const [FamilyInteractionService] = useAtom(familyInteractionService);
  const isLoading = false;
  const isSubmitted = false;

  const initialValues = isUndefined(familyInteractions)
    ? FamilyInteractionFactory(navAtomProps.studentId)
    : (PutFamilyInteractionFactory(familyInteractions as PutFamilyInteractionDto) as InteractionDto);

  const sortedStudents = sortBy(students ?? [], [(s) => s.lastName], (s) => s.firstName);
  const studentOptions = sortedStudents?.map((s) => ({ value: s.id, key: `${s.fullName}` })) ?? [];

  const onSubmit = async (values: any) => {
    try {
      if (!familyInteractions) {
        const created = await FamilyInteractionService.postFamilyInteractions(school?.id ?? 0, values);
        nav(`/schools/${navAtomProps.schoolId}/family-interaction/${created?.id}`, { replace: true });
        toast({ description: 'Family interaction added', status: 'success' });
      } else {
        await FamilyInteractionService.putFamilyInteractions(school?.id ?? 0, values);
        toast({ description: 'Family interaction updated', status: 'success' });
      }
      setFamilyInteractions(RESET);
    } catch (error: any) {
      errToast(error);
      setFamilyInteractions(RESET);
    }
  };

  const onAddAttempt = (push: any) => {
    push(FamilyInteractionAttemptFactory());
  };

  useEffect(() => {
    if (familyInteractions && formRef.current) {
      formRef.current.resetForm();
    }
  }, [familyInteractions]);

  const student = useAtomValue(studentAtom) as undefined | StudentDto;

  return (
    <FfFormikForm
      initialValues={initialValues}
      validationClass={familyInteractions ? PutFamilyInteractionDto : PostFamilyInteractionDto}
      submit={onSubmit}
      formRef={(a) => {
        formRef.current = a;
      }}
    >
      {({ values, isSubmitting, errors }) => (
        <Form>
          <DebugCard data={[{ errors: 'errors', data: errors }]} />
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem pr="2em">
              <FfFormikSelect
                id="studentId"
                label={`Student Name`}
                options={studentOptions}
                isDisabled={!!navAtomProps.studentId || !!familyInteractions}
                onChange={(option) => setStudentId(+(option?.value ?? '0'))}
              />
            </GridItem>
            <GridItem rowSpan={2} pl="2em">
              <Stack direction="column" spacing={4}>
                <HStack>
                  <Text fontWeight="bold">Teacher:</Text>
                  <Text>{student?.teacher}</Text>
                </HStack>
                <FecList />
                <IcList />
              </Stack>
            </GridItem>
            <GridItem pr="2em">
              <FfFormikInput setDefaultToUndef={true} type="text" label="Reason for Interaction" id="reason" />
            </GridItem>
          </Grid>
          <Flex mt="1em" width="100%">
            <Box flex={1}>
              <FieldArray name="attempts">
                {({ remove, push }) => (
                  <div>
                    {values.attempts.length > 0 &&
                      values.attempts.map((attempt, index: number) => (
                        <div key={index}>
                          <Box padding="15px  0px" mb="30px" mt="50px" borderBottom="2px">
                            <Box bgColor="ff.white" padding="15px">
                              <Flex>
                                <Box w="99%">
                                  <Heading>Interaction Attempt {index + 1}</Heading>
                                </Box>
                                <Box w="1%">
                                  <Link onClick={() => remove(index)}>
                                    <CloseIcon></CloseIcon>
                                  </Link>
                                </Box>
                              </Flex>
                              <GenericInteractionForm
                                index={index}
                                isLoading={isLoading}
                                isSubmitted={isSubmitted}
                                attemptValues={attempt}
                              />
                              <HomeVisitForm index={index} isLoading={isLoading} isSubmitted={isSubmitted} attempt={attempt} />
                            </Box>
                          </Box>
                        </div>
                      ))}

                    <Box borderBottom="2px" textAlign="center" pb="50px">
                      <Link onClick={() => onAddAttempt(push)} color={'ff.blue'} fontWeight="bold" fontSize="large">
                        <AddIcon display="inline-block" pb="5px"></AddIcon> <Text display="inline-block">Add Attempt</Text>
                      </Link>
                    </Box>
                  </div>
                )}
              </FieldArray>
            </Box>
          </Flex>
          <Flex mt="20px">
            <Box flex={1} textAlign="right">
              <Button
                bg={'ff.magenta'}
                _hover={{ backgroundColor: 'ff.magenta' }}
                color="ff.white"
                size="lg"
                isLoading={isSubmitting}
                onClick={async () => {
                  if (!formRef.current) return Promise.resolve();
                  const validationErrors = await formRef.current.validateForm();
                  if (Object.keys(validationErrors).length > 0) {
                    const touchFields = setNestedObjectValues<any>(validationErrors, true);
                    formRef.current.setTouched(touchFields);
                    return Promise.resolve();
                  }
                  formRef.current.submitForm();
                  return Promise.resolve();
                }}
              >
                Save All
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </FfFormikForm>
  );
};
