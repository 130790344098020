import { Navigate, Route, Routes } from 'react-router-dom';
import { useAtom } from 'jotai';
// import { useAtomsDevtools, useAtomsDebugValue } from 'jotai/devtools';
import { StaffDirectoryScreen } from './screens/Users/StaffDirectoryScreen';
import { UserDetailScreen } from './screens/Users/UserDetailScreen';
import { EditUserScreen } from './screens/Users/EditUserScreen';
import { StaffActivateUsersScreen } from './screens/Users/StaffActivateUsersScreen';
import { SitesScreen } from './screens/Users/SitesScreen';
import { StudentsScreen } from './screens/IC/StudentsScreen';
import { ActivateStudentsScreen } from './screens/IC/ActivateStudentsScreen';
import { StudentDetailScreen } from './screens/IC/StudentDetailScreen';
import { EditStudentScreen } from './screens/Users/EditStudentScreen';
import { TutorsScreen } from './screens/IC/TutorsScreen';
import { TutorDetailScreen } from './screens/IC/TutorDetailScreen';
import { TutorDashboardScreen } from './screens/Tutor/TutorDashboardScreen';
import { TutorAssignmentScreen } from './screens/IC/TutorAssignmentScreen';
import { SchoolDetailScreen } from './screens/Users/SchoolDetailScreen';
import { LessonPlansByTutorScreen } from './screens/IC/LessonPlansByTutorScreen';
import { ObservationsScreen } from './screens/Observations/ObservationsScreen';
import { LoginScreen } from './screens/Login/LoginScreen';
import { NoRoleScreen } from './screens/Login/NoRoleScreen';
import { LessonPlansScreen } from './screens/IC/LessonPlans';
import { LessonPlanDetailScreen } from './screens/Tutor/LessonPlanDetailScreen';
import { ObserveTutorSelectionScreen } from './screens/IC/ObserveTutorSelectionScreen';
import { ObserveTutorFormScreen } from './screens/IC/ObserveTutorFormScreen';
import { IntentionalError } from './screens/IntentionalError';
import { IParams } from './atoms/navAtom';
import { DefaultLayout } from './components/Layouts/DefaultLayout';
import { FfGuard } from './authGuard/FfGuard';
import { LoginLayout } from './components/Layouts/LoginLayout';
import { UnauthorizedScreen } from './screens/Login/UnauthorizedScreen';
import { NavigateToHomePage } from './components/Routes/RedirectToHomePage';
import { userInfoAtom } from './ffApi';
import { Dashboard as ICDashboard } from './screens/IC/Dashboard';
import { Dashboard as FECDashboard } from './screens/FEC/Dashboard';
import { LessonPlanCreateScreen, ObservationCreateScreen } from './screens/Tutor/LessonPlanCreateScreen';
import { SchoolActivateUsersScreen } from './screens/Users/SchoolActivateUsersScreen';
import { SchoolDirectoryScreen } from './screens/Users/SchoolDirectoryScreen';
import { SchoolUserDetailScreen } from './screens/Users/SchoolUserDetailScreen';
import { FormsScreen } from './screens/FormsScreen';
import { NoRoleLayout } from './components/Layouts/NoRoleLayout';
import { AllInteractionsScreen } from './screens/FEC/AllInteractionsScreen';
import { FamilyInteractionScreen } from './screens/FEC/FamilyInteractionScreen';

import { FamilyEventCreateUpdateScreen } from './screens/FEC/FamilyEventCreateUpdateScreen';
import { FamilyEventsScreen } from './screens/FEC/FamilyEventsScreen';
import { LogoutScreen } from './screens/Login/LogoutScreen';
import { StudentEnrollmentScreen } from './screens/IC/EnrollmentHistoryScreen';

export const resolveHomePage = (params: IParams) => (params.schoolId ? `/schools/${params.schoolId}/dashboard` : `/schools`);

// const DebugAtoms = ({ children }: { children: any }) => {
//   useAtomsDevtools('demo');
//   useAtomsDebugValue();
//   return <>{children}</>;
// };

export const FfRoutes = () => {
  const [user] = useAtom(userInfoAtom);

  return (
    <Routes>
      <Route path="/" element={<NavigateToHomePage />} />
      <Route
        path="/schools/:schoolId/dashboard"
        element={
          <DefaultLayout providerKey={0}>
            <FfGuard roles={['IC', 'FEC', 'Tutor', 'Staff']}>
              {user?.role === 'IC' && <ICDashboard />}
              {user?.role === 'Staff' && <ICDashboard />}
              {user?.role === 'FEC' && <FECDashboard />}
              {user?.role === 'Tutor' && <TutorDashboardScreen />}
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/error-boundary-example/'}
        element={
          <DefaultLayout providerKey={1} bg={'ff.lightPink'}>
            <IntentionalError />
          </DefaultLayout>
        }
      />
      <Route
        path={'/users'}
        element={
          <DefaultLayout providerKey={2} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff']}>
              <StaffDirectoryScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/users'}
        element={
          <DefaultLayout providerKey={3} bg={'ff.lightPink'}>
            <FfGuard roles={['IC', 'FEC']}>
              <SchoolDirectoryScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path="/schools/:schoolId/users/:staffId"
        element={
          <DefaultLayout providerKey={4} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <SchoolUserDetailScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path="/users/:staffId"
        element={
          <DefaultLayout providerKey={5} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff']}>
              <UserDetailScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path="/users/:staffId/edit"
        element={
          <DefaultLayout providerKey={6} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff']}>
              <EditUserScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/users/activate'}
        element={
          <DefaultLayout providerKey={7} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff']}>
              <StaffActivateUsersScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/users/activate'}
        element={
          <DefaultLayout providerKey={8} bg={'ff.lightPink'}>
            <FfGuard roles={['IC']}>
              <SchoolActivateUsersScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools'}
        element={
          <DefaultLayout providerKey={9} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC', 'Tutor']}>
              <SitesScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students'}
        element={
          <DefaultLayout providerKey={10} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC', 'Tutor']}>
              <StudentsScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/family-interactions'}
        element={
          <DefaultLayout providerKey={10} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <AllInteractionsScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId/family-interaction/create'}
        element={
          <DefaultLayout providerKey={10} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <FamilyInteractionScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/family-interaction/create'}
        element={
          <DefaultLayout providerKey={10} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <FamilyInteractionScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/family-interaction/:familyInteractionId'}
        element={
          <DefaultLayout providerKey={10} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <FamilyInteractionScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId'}
        element={
          <DefaultLayout providerKey={11} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC', 'Tutor']}>
              {(user?.role === 'IC' || user?.role === 'FEC' || user?.role === 'Staff') && <StudentDetailScreen tutor={false} />}
              {user?.role === 'Tutor' && <StudentDetailScreen tutor={true} />}
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId/edit'}
        element={
          <DefaultLayout providerKey={12} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <EditStudentScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId/enrollment'}
        element={
          <DefaultLayout providerKey={12} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC', 'Tutor']}>
              <StudentEnrollmentScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/activate'}
        element={
          <DefaultLayout providerKey={13} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC']}>
              <ActivateStudentsScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors'}
        element={
          <DefaultLayout providerKey={14} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <TutorsScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors/:tutorId'}
        element={
          <DefaultLayout providerKey={15} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <TutorDetailScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors/:tutorId/dashboard'}
        element={
          <DefaultLayout providerKey={16} bg={'ff.water'}>
            <FfGuard roles={['Tutor']}>
              <TutorDashboardScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors/assign'}
        element={
          <DefaultLayout providerKey={17} bg={'ff.lightPink'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <TutorAssignmentScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/districts/:districtId/schools/:schoolId'}
        element={
          <DefaultLayout providerKey={18} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <SchoolDetailScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors/lesson-plans'}
        element={
          <DefaultLayout providerKey={19} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <LessonPlansByTutorScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/observations'}
        element={
          <DefaultLayout providerKey={19} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <ObservationsScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors/:tutorId/observe'}
        element={
          <DefaultLayout providerKey={20} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC']}>
              <ObserveTutorSelectionScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/tutors/:tutorId/observe/:lessonPlanId'}
        element={
          <DefaultLayout providerKey={21} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC']}>
              <ObserveTutorFormScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/login'}
        element={
          <LoginLayout>
            <LoginScreen />
          </LoginLayout>
        }
      />
      <Route
        path={'/logout'}
        element={
          <LoginLayout>
            <LogoutScreen />
          </LoginLayout>
        }
      />
      <Route
        path={'/inactive'}
        element={
          <NoRoleLayout>
            <NoRoleScreen />
          </NoRoleLayout>
        }
      />
      <Route
        path={'/unauthorized'}
        element={
          <LoginLayout>
            <UnauthorizedScreen />
          </LoginLayout>
        }
      />
      <Route path={'/callback'} element={<Navigate to={`/`} replace={true} />} />
      <Route
        path={'/schools/:schoolId/lesson-plans'}
        element={
          <DefaultLayout providerKey={22} bg={'ff.water'}>
            <LessonPlansScreen />
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId/lesson-plans/create'}
        element={
          <DefaultLayout providerKey={23} bg={'ff.palePalePink'}>
            <LessonPlanCreateScreen />
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId/lesson-plans/:lessonPlanId'}
        element={
          <DefaultLayout providerKey={24} bg={'ff.palePalePink'}>
            <LessonPlanDetailScreen />
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/students/:studentId/observe/create'}
        element={
          <DefaultLayout providerKey={29} bg={'ff.water'}>
            <ObservationCreateScreen />
          </DefaultLayout>
        }
      />
      <Route
        path={'/forms'}
        element={
          <DefaultLayout providerKey={25} bg={'ff.water'}>
            <FormsScreen />
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/family-events'}
        element={
          <DefaultLayout providerKey={26} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <FamilyEventsScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/family-events/create'}
        element={
          <DefaultLayout providerKey={27} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <FamilyEventCreateUpdateScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />
      <Route
        path={'/schools/:schoolId/family-events/:familyEventId'}
        element={
          <DefaultLayout providerKey={28} bg={'ff.water'}>
            <FfGuard roles={['Staff', 'IC', 'FEC']}>
              <FamilyEventCreateUpdateScreen />
            </FfGuard>
          </DefaultLayout>
        }
      />

      <Route path="*" element={<p>404</p>} />
    </Routes>
  );
};
