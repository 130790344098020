/* eslint-disable max-classes-per-file */
import { PostRescheduleLessonDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { IsDate, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { DefaultErrorModel } from '../../utils/dateTransformer';

export enum DialogStages {
  Start = 1,
  ScheduledSession = 2,
  CustomSession = 3,
  Pending = 4,
  Conflict = 5,
  Closed = 6,
  Done = 7,
}

export enum DialogOperation {
  Duplicate = 1,
  MakeUp = 2,
}

export class RescheduleErrorData {
  tutorName: string;

  studentName: string;

  @IsDate()
  startDateTime: Date;

  @IsDate()
  endDateTime: Date;
}

export class RescheduleError extends DefaultErrorModel<RescheduleErrorData> {
  @Type(() => RescheduleErrorData)
  public data: RescheduleErrorData;
}

export type FinalSchedule = { startDate: Date; endDate: Date } | SessionLessonPlanDto;

export type RescheduleStateOptions =
  | { stage: DialogStages.Start }
  | { stage: DialogStages.ScheduledSession; scheduledSession?: SessionLessonPlanDto }
  | { stage: DialogStages.CustomSession; startDate?: Date; endDate?: Date }
  | {
      stage: DialogStages.Conflict;
      scheduledSession?: SessionLessonPlanDto;
      startDate?: Date;
      endDate?: Date;
      error: RescheduleErrorData;
      message: string;
    }
  | { stage: DialogStages.Pending; startDate: Date; endDate: Date }
  | { stage: DialogStages.Pending; scheduledSession: SessionLessonPlanDto }
  | { stage: DialogStages.Done; redirect: string };

export interface LessonPlanRescheduleState {
  dialogOperation: DialogOperation;
  state: RescheduleStateOptions;
  originalSession: SessionLessonPlanDto;
}

export interface SaveReschedule {
  post: PostRescheduleLessonDto;
  studentId: number;
}

export type OperationStages = DialogStages.Start | DialogStages.ScheduledSession | DialogStages.CustomSession | DialogStages.Conflict;

export interface LessonPlanReschedule {
  dialogStage: DialogStages.ScheduledSession;

  scheduledSession: SessionLessonPlanDto;

  makeupLessonPlanId?: number;
}

export interface LessonPlanCustom {
  dialogStage: DialogStages.CustomSession;

  makeupLessonPlanId?: number;

  startDate: Date;

  endDate: Date;
}

export interface SaveValues {
  studentId: number;
  lessonPlanType: number;
  attendanceStatus: number;
  byUserId: number;
  date: string;
}

export interface RescheduleForm {
  id: number;
  studentId: number;
  startDate: string;
  byUserId: number;
  endDate: string;
  weekDay?: string;
  weekOrdinal?: number;
}

// Cases: duplicate, make up

// Duplicate
// link: ScheduleMakeupLessonPlan
//   - current lesson is in atom
//   - using schedule for start, end, weekDay, weekOrdinal
//     - use selected schedule
//   - using custom for start, end, weekDay, weekOrdinal
//     - if in schedule

//   - validation:
//     - current lesson is not an absence
//     - current lesson is not outside of the enrollment period

// in the system we have scheduled sessions, and saved lesson plans.
// scheduled sessions are based on set weekdays and times.
// when the user decides to create a lesson plan, they choose a scheduled session

// theory if it is a makeup lesson we cannot take the slot of a scheduled date, if it is a duplicate lesson we can take the slot of a scheduled date

/* Derived state

  scheduledSessions: ScheduledSession[]
  errorMessage: string
  conflictingSession: ScheduledSession

*/

/* Storing lesson

  makeup case
    - copy lesson content
    - copy lesson header with different date
    - ensure weekDay and weekOrdinal are correct
    - verify the existing lesson plan is an absence
    - verify the existing lesson plan is not outside of the enrollment period

  duplicate case
    - verify the existing lesson plan is not an absence
    - same as above

  lessonPlanCopyDialogAtom: This is the state that drives the dialog independently

  copyLessonPlanAtom: Receives originalId, startDate, endDate, weekDay, weekOrdinal
      - gets the original lesson plan
      - creates a new lesson plan with the new dates
      - creates a new lesson plan header with the new dates
      - posts the new lesson plan

  state creation functions:
    - createLessonPlanDuplicate(lessonPlanId, startDate, endDate)
    - createLessonPlanMakeUp(lessonPlanId, startDate, endDate)


  weekDay and weekOrdinal: 
    - pass them to the backend from the list of scheduled sessions
    - for custom dates and times we need to validate
      - existing lesson plans excluding absences
      - scheduled sessions not saved yet as lesson plans    
*/

/* 
Questions: will the list of scheduled sessions include conflicts, on student or on teacher?
  - since we are using weekDay and weekOrdinal, we can't have conflicts by the same student and tutor
  - for custom date and times, the potential for conflicts is there
  - the conflicts are checked against non absent saved lessons and scheduled lessons

Questions: When using a schedule we know the weekDay and weekOrdinal, but when using a custom date we don't know the weekDay and weekOrdinal
  - the schedule is a list of lessons to be created on a given weekDay and weekOrdinal, they are calculated by the backend code.
  - if there is a match to the schedule on weekDay, we can get the weekDay and weekOrdinal from the matching schedule
  - otherwise we leave weekOrdinal null??



Talk about times vs dates
Set up all state, props, dialog location. 
Add/modify calls to dialog, calls to server
Merge in time picker, and start/end date DB fields ( use input fields as stand-ins for now )

*/

/*
List of code changes and additions:

Code changes: C:\Projects\EdAnalytics\ff_be_se\src\api\school\student\lesson-plan\lesson-plan.controller.ts
Code changes: C:\Projects\EdAnalytics\ff_be_se\src\api\school\student\lesson-plan\lesson-plan.service.ts
      makeUpLessonPlan: Add weekDay, weekOrdinal as optional parameters


Fill in Jon's.
Add 5-6 tasks on productive.
Validate against the notes by bak.


Code references: C:\Projects\EdAnalytics\ff_fe_se\src\components\LessonPlan\LessonPlanMakeup.tsx
    - current dialog window opens on top of the lesson plan at navigation
    - asks if user wants to create a makeup lesson plan
    - the options are new content or copy content
*/

/*

I'll give you a short system description and a theory I need to validate based on the description. By theory I mean an implication or conclusion, that I got from the description and conversation. And I need to validate before calling up the user. In your answer please quote the relevant fragments of the system description

```
In the system we have scheduled sessions, and saved lesson plans.
scheduled sessions are based on set weekdays and times.
when the user decides to create a lesson plan, they choose a scheduled session

By default, lock date and start/end times, 
Lesson Plan screen shows “Schedule a Make Up Lesson Plan” link below “Attendance” dropdown only if Session is Absence 

On click, prompt user to the following options: 
 
Header: Schedule Make Up Lesson Plan
Use a scheduled Session: 
User must select from list of upcoming scheduled sessions (available slots) 
Sessions are according to tutor/student assignment
28 days ahead
Display as [Date], [Start Time] - [End Time] 
On save, create Lesson Plan for selected scheduled slot as a Make-Up Lesson Plan 
Copy Lesson Plan info and module content
IsMakeUp = true 
WeekDay = slot’s weekDay 
WeekOrdinal = slot’s weekOrdinal 
OrigID (new field) = original lesson plan ID 
=> redirect to Make Up Lesson Plan 
Enter custom date and start/end times 
On save, validate date and start/end times (see below for conflict rules) 
If there's a conflict, warn user  
The date/time you’ve selected conflicts with the following session: 
[Student Name] [Session/Lesson Plan Date/Time] 
Please select a scheduled session or change date/time. 

On “Take Me Back”, return to main prompt (Scheduled Session or Custom Date/Time) 
If no conflict, create Lesson Plan for selected scheduled slot as a Make-Up Lesson Plan 
Copy Lesson Plan info and module content
IsMakeUp = true 
WeekDay = null 
WeekOrdinal = null 
OrigID (new field) = original lesson plan ID 
=> redirect to Make Up Lesson Plan 

Scheduling conflict:
Student has an existing Lesson Plan on that date and within that start/end time based on the Student's schedule (Student may be multiple Tutors)
Tutor has an existing Lesson Plan on that date and within that start/end time based on Tutor's schedule
If Student is absent on that time slot, allow to schedule for that date/time
```

THEORY: if it is a makeup lesson we cannot take the slot of a scheduled date, if it is a duplicate lesson we can take the slot of a scheduled date



A user can copy their lesson to any time so long there is not either an existing non-absent saved lesson for the same tutor and student. And also excluding times when the tutor or the student are independently scheduled. regardless of being an absent lesson that holds the content.

A user can create a makeup class and in addition to the duplication checks, it cannot take the place of a class on the schedule for the specific tutor student combination, And it has to come from an absence.


*/
