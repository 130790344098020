import { Box, Text } from '@chakra-ui/react';
import { FFRoles, getFFRolesString } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import React from 'react';
import { userInfoAtom } from '../ffApi';
import { DistrictServiceAtoms } from '../services/District';
import { SchoolServiceAtoms } from '../services/School';
import { DebugCard } from './DebugCard';
import { FfRouterLink as Link } from './FfRouterLink';

export const SchoolSelect: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [district] = useAtom(DistrictServiceAtoms.getCurrentDistrict);
  const [userInfo] = useAtom(userInfoAtom);

  const schoolSelected = school !== undefined;

  const showChangeSchool = (userInfo.role === getFFRolesString(FFRoles.Staff) || (userInfo?.schoolsIds?.length ?? 0)) && schoolSelected;

  return (
    <Box>
      <Text color="ff.white" fontWeight="bold">
        {district?.name}
      </Text>
      <Text color="ff.white">{school?.name}</Text>
      {showChangeSchool === true && (
        <Link textDecoration="underline !important" color="ff.white" to="/schools">
          Change
        </Link>
      )}
      <DebugCard data={[{ name: 'role', data: userInfo?.role }]} />
    </Box>
  );
};
