import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Grid, GridItem, Link } from '@chakra-ui/react';
import { FunctionComponent, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TutorAppointment } from '../../models/TutorAppointment';
import { SchoolServiceAtoms } from '../../services/School';
import { DayOfWeekTimePicker } from '../DayOfWeekTimePicker/DayOfWeekTimePicker';
import { DayPicker } from '../DayPicker/DayPicker';
import { TimePicker } from '../TimePicker';
import { TutorTarget } from './TutorTarget';
import { useScheduleFormArray } from './useScheduleFormArray';

const maxAppointments = 5;

export interface StudentTutorScheduleProps {
  studentName: string;
  schoolId: number;
  studentId: number;
}

const StudentTutorRow: FunctionComponent<{ appointment: TutorAppointment; index: number; studentId: number }> = ({
  appointment,
  index,
  studentId,
}) => {
  const { updateAppointment, removeAppointment, updateTutor, setHasError } = useScheduleFormArray({ studentId });

  return (
    <Fragment key={index}>
      <GridItem display={'flex'}>
        <DayPicker dayOfWeek={appointment.dayOfWeek as number} cb={(d) => updateAppointment(index, d.dayOfWeek)} />
        <></>
      </GridItem>
      <GridItem display={'flex'}>
        <TimePicker
          id={`time`}
          startTime={appointment.startTime as string}
          endTime={appointment.endTime as string}
          format="hh:mm a"
          onChange={(d) => updateAppointment(index, undefined, d.startTime, d.endTime)}
          onError={(e) => setHasError(`${studentId}:${index}`, !!e)}
          rangeMode
        />
      </GridItem>
      <GridItem>
        <TutorTarget tutorId={appointment.tutorId} tutorName={appointment.tutorName} cb={(id, name) => updateTutor(index, id, name)} />
      </GridItem>
      <GridItem>
        <CloseIcon cursor="pointer" onClick={() => removeAppointment(index)} />
      </GridItem>
    </Fragment>
  );
};

export const StudentTutorSchedule: FunctionComponent<StudentTutorScheduleProps> = ({ schoolId, studentId, studentName }) => {
  const { appts, addAppointment } = useScheduleFormArray({ studentId });

  return (
    <Grid display={'flex'} flexDirection={'column'} templateColumns="25% 75%">
      <GridItem id="student-col" marginBottom="15px">
        <Box>
          <Link as={RouterLink} to={`/schools/${schoolId}/students/${studentId}`}>
            {studentName}
          </Link>
        </Box>
      </GridItem>
      <GridItem>
        <Grid templateColumns="16% 56% 28%">
          <GridItem>
            <Box w="30%" as="b">
              Day Of The Week
            </Box>
          </GridItem>
          <GridItem>
            <Box w="40%" as="b">
              Start/End Time
            </Box>
          </GridItem>
          <GridItem>
            <Box w="30%" as="b">
              Tutor
            </Box>
          </GridItem>
        </Grid>
        <Box>
          <Grid templateColumns="15% 55% 22% 10%" padding={'0px 0px 10px 0px'} gap="2" alignItems="center">
            {appts.map((appointment, index) => (
              <StudentTutorRow key={index} appointment={appointment} index={index} studentId={studentId} />
            ))}
          </Grid>
          {appts && appts.length < maxAppointments && (
            <Button leftIcon={<AddIcon />} variant={'ghost'} onClick={addAppointment}>
              Add a Day
            </Button>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};
