import { FilterFn, sortingFns, SortingFn } from '@tanstack/react-table';
import { rankItem, compareItems } from '@tanstack/match-sorter-utils';
import { SchoolDto } from '@edanalytics/ff_be_se';
import { DateTime as DT } from 'luxon';
import { isDate } from 'lodash';

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const fullNameFilter: FilterFn<any> = (row, columnId, value) =>
  row.original[columnId]?.toLowerCase()?.includes(value.toLowerCase()) ?? false;

export const observerFilter: FilterFn<any> = (row, columnId, value) =>
  row.original[columnId]?.toLowerCase()?.includes(value.toLowerCase()) ?? false;

export const studentNodeFilter: FilterFn<any> = (row, columnId, value) =>
  (row.original.student?.[columnId] ?? '').toLowerCase().includes(value.toLowerCase());

export const byUserNodeFilter: FilterFn<any> = (row, columnId, value) =>
  row.original.byUser?.fullName.toLowerCase().includes(value.toLowerCase());

export const roleNodeFilter: FilterFn<any> = (row, columnId, value) => row.original.role?.name.toLowerCase().includes(value.toLowerCase());

export const schoolsNodeFilter: FilterFn<any> = (row, columnId, value) =>
  row.original.schools?.some((s: SchoolDto) => s.name.toLowerCase().includes(value.toLowerCase()));

export const dateFilter: FilterFn<any> = (row, columnId, value) => {
  const colValue = row.original[columnId];
  if (colValue === undefined) return false;

  const stringValue = isDate(colValue) ? colValue.toISOString() : colValue;

  const minValue = value?.[0];
  const maxValue = value?.[1];
  const dateLocal = DT.fromISO(stringValue).toLocal().toFormat('yyyy-MM-dd');
  const minDateLocal = minValue ? DT.fromJSDate(minValue).toLocal().toFormat('yyyy-MM-dd') : undefined;
  const maxDateLocal = maxValue ? DT.fromJSDate(maxValue).toLocal().toFormat('yyyy-MM-dd') : undefined;

  return (!minDateLocal || dateLocal >= minDateLocal) && (!maxDateLocal || dateLocal <= maxDateLocal);
};

// eslint-disable-next-line no-nested-ternary
export const compareValues = (a: any, b: any) => (a === b ? 0 : a < b ? -1 : 1);

export const fuzzySort: SortingFn<any> = (rowA: any, rowB: any, columnId: any) => {
  let dir = 0;
  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
  }
  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const userSort: SortingFn<any> = (rowA: any, rowB: any) => compareValues(rowA.original.fullName, rowB.original.fullName);

export const studentSort: SortingFn<any> = (rowA: any, rowB: any) =>
  compareValues(rowA.original.student?.fullName, rowB.original.student?.fullName);

export const byUserSort: SortingFn<any> = (rowA: any, rowB: any) =>
  compareValues(rowA.original.byUser?.fullName, rowB.original.byUser?.fullName);

export const roleSort: SortingFn<any> = (rowA: any, rowB: any) => compareValues(rowA.original.role?.name, rowB.original.role?.name);

export const columnIdValueSort: SortingFn<any> = (rowA: any, rowB: any, columnId: string) =>
  compareValues(rowA.original[columnId], rowB.original[columnId]);
