import {
  Box,
  ButtonGroup,
  Checkbox,
  Container,
  Flex,
  Text,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
  Button,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  FamilyEventAttendanceDto,
  FamilyEventDto,
  PostFamilyEventDto,
  PutFamilyEventDto,
  StudentDto,
  StudentEnrollmentDto,
  SubmitFamilyEventDto,
} from '@edanalytics/ff_be_se';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { ColumnDef } from '@tanstack/react-table';
import createValidator from 'class-validator-formik';
import { DateTime as DT } from 'luxon';
import { DebugCard } from '../../components/DebugCard';
import { FfDivider } from '../../components/FfDivider';
import { persistNavAtom } from '../../atoms/navAtom';
import { SchoolServiceAtoms } from '../../services/School';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { atomApiWithNavAndRead, atomApiWithNavAndUpdate } from '../../utils/async-atom';
import { FamilyEventForm } from '../../components/FamilyEvents/FamilyEventForm';
import { FamilyEventService, FamilyEventServiceAtoms } from '../../services/FamilyEvent/FamilyEventService';
import { StudentLink, StudentSummaryLink } from '../../components/Utils';
import { columnIdValueSort, studentSort } from '../../components/FfTable/Filters';
import { FfTable } from '../../components/FfTable';
import { StudentService as studentService } from '../../services/Student';
import { ConfirmAlert } from '../../components/ConfirmationDialog';
import { Empty } from '../../components/Empty';
import { FfButtonTypes } from '../../models/Button';
import { inRange } from '../../utils/TimeHelper';

export const studentIdAtom = atom(undefined as number | undefined);

export const eventDateAtom = atom(undefined as Date | undefined);

interface EventAttendance extends Partial<FamilyEventAttendanceDto> {
  student: StudentDto;
  rowHighlightClass?: 'rowHighlightNew';
}

interface Student extends StudentDto {
  rowHighlightClass?: 'rowHighlightNew';
}

const studentsAtom = atomApiWithNavAndRead(async (get, nav): Promise<StudentDto[]> => {
  const eventDate = get(eventDateAtom);
  if (!eventDate) return [];

  const schoolId = nav?.schoolId as number;
  const students = await get(studentService).getStudents(schoolId);

  const enrollmentTasks = students.map((student) => get(studentService).getStudentEnrollment(schoolId, student.id));

  const allEnrollments = await Promise.all(enrollmentTasks);

  const enrollmentsInRange = allEnrollments
    .map((enrollments) =>
      enrollments.find((e) =>
        inRange(eventDate, {
          start: DT.fromISO(e.entryDate as unknown as string).toJSDate(),
          end: e.exitDate ? DT.fromISO(e.exitDate as unknown as string).toJSDate() : eventDate,
        }),
      ),
    )
    .filter((a) => !!a) as StudentEnrollmentDto[];

  const result = students.map((s) => ({ ...s, active: !!enrollmentsInRange.find((e) => e.studentId === s.id) })) as StudentDto[];

  return result;
});

const deleteFamilyEventAtom = atom(null, async (get, set, newValue: { schoolId: number; familyEventId: number }) => {
  await get(FamilyEventService).deleteFamilyEvent(newValue.schoolId, newValue.familyEventId);
  set(FamilyEventServiceAtoms.getAllFamilyEvents, RESET);
});

export const studentEnrollmentsAtom = (id: number) =>
  atomApiWithNavAndRead((get, nav) => {
    const schoolId = nav?.schoolId;
    const studentId = id;
    if (!schoolId || !studentId) return Promise.resolve(undefined);

    return get(studentService).getStudentEnrollment(schoolId, studentId);
  });

const familyAttendanceFromStudent = (s: StudentDto, feid: number | undefined, boolNew: boolean): EventAttendance => ({
  familyEventId: feid,
  student: s,
  studentId: s.id,
  studentAttended: false,
  numberRegistered: 0,
  numberAttended: 0,
  rowHighlightClass: boolNew ? 'rowHighlightNew' : undefined,
});

const buildFinalAttendanceData = (students: StudentDto[], loadAttendanceUpdates: boolean, familyEvent?: FamilyEventDto) => {
  // is it's a new record, there is no attendance to work with or the user has not refreched for updates
  if (!loadAttendanceUpdates) {
    return {
      updatedAttendanceData:
        familyEvent?.attendance ?? students.filter((s) => s.active).map((s) => familyAttendanceFromStudent(s, undefined, false)),
      newStudents: [],
      removedStudents: [],
    };
  }

  // const isStudentActive = Object.fromEntries(students.map((s) => ([s.id, s.active])));
  const isInAttendance = Object.fromEntries(familyEvent?.attendance?.map((s) => [s.studentId, true]) ?? []);

  // students active as of date that are not in the attendance list
  const newStudents: Student[] = students
    .filter((s) => s.active && !isInAttendance[s.id])
    .map((s) => ({ ...s, rowHighlightClass: 'rowHighlightNew' } as Student));

  // students inactive as of date that are in the attendance list
  // const removedStudents: Student[] = familyEvent.attendance.filter((s) => !isStudentActive[s.studentId])
  const removedStudents: Student[] = students.filter((s) => !s.active && isInAttendance[s.id]);

  // all students   // attendance data without inactive students
  const updatedAttendanceData: EventAttendance[] = [];

  students
    .filter((s) => s.active)
    .forEach((student) => {
      if (isInAttendance[student.id]) {
        const existingStudent = familyEvent?.attendance?.find((s) => s.studentId === student.id) as EventAttendance;
        updatedAttendanceData.push(existingStudent as EventAttendance);
      } else {
        const newStudent = newStudents?.some((s) => s.id === student.id);
        updatedAttendanceData.push(familyAttendanceFromStudent(student, familyEvent?.id, newStudent) as EventAttendance);
      }
    });

  return { updatedAttendanceData, newStudents, removedStudents };
};

export const FamilyEventCreateUpdateScreen: React.FunctionComponent = () => {
  const nav = useNavigate();
  const toast = useToast();
  // ATOMS
  const [navAtomProps] = useAtom(persistNavAtom);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [students, setStudents] = useAtom(studentsAtom);
  const [familyEvent, setFamilyEvent] = useAtom(FamilyEventServiceAtoms.getCreateOrUpdateFamilyEvent);
  const deleteFamilyEvent = useUpdateAtom(deleteFamilyEventAtom);
  const [eventDateFilter, setEventDateFilter] = useAtom(eventDateAtom);

  // LOCAL STATE
  const formRef = React.createRef<HTMLFormElement>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = React.useState(false);
  const [loadAttendanceUpdates, setLoadAttendanceUpdates] = React.useState(false);
  const [newStudents, setNewStudents] = React.useState<StudentDto[]>([]);
  const [removedStudents, setRemovedStudents] = React.useState<StudentDto[]>([]);
  const [attendanceData, setAttendanceData] = React.useState<any[]>([]);

  // if the event has been created, updating the list does not apply
  const isRefreshSummaryVisible = newStudents.length > 0 || removedStudents.length > 0;
  const isSubmitted = !!familyEvent?.submittedAt;
  const isEventNotFound = navAtomProps.familyEventId && !familyEvent;

  const eventScreenTitle = () => {
    if (!navAtomProps.familyEventId) {
      return 'Add an ';
    }
    if (isSubmitted) return 'See ';
    return 'Edit ';
  };

  // INITIALIZE or RE-INITIALIZE on save
  useEffect(() => {
    const selectedDate = familyEvent?.startDate ? DT.fromISO(familyEvent?.startDate).toJSDate() : new Date();
    selectedDate.setHours(0, 0, 0, 0);
    if (!eventDateFilter || selectedDate.getTime() !== eventDateFilter.getTime()) {
      setEventDateFilter(selectedDate);
    }

    const {
      newStudents: newStudentsInt,
      removedStudents: removedStudentsInt,
      updatedAttendanceData: attendanceDataInt,
    } = buildFinalAttendanceData(students, loadAttendanceUpdates, familyEvent);

    setNewStudents(newStudentsInt);
    setRemovedStudents(removedStudentsInt);
    setAttendanceData(attendanceDataInt);
    setIsConfirmSaveModalOpen(false);
    setIsDeleteModalOpen(false);

    // AFTER SAVING
    if (isSaving || submitClicked) {
      setIsSaving(false);
      setSubmitClicked(false);
      const action = submitClicked ? 'submitted' : 'saved';
      toast({ description: `Family event ${action}`, status: 'success' });
      setFamilyEvent(RESET);
    }
  }, [students, familyEvent, eventDateFilter]);

  useEffect(() => {
    if (!loadAttendanceUpdates)
      // Only true after refresh, not on mount
      return;

    toast({ description: 'Student list is now up-to-date', status: 'success' });
  }, [newStudents, removedStudents]);

  // Redirect to /{familyEventId} URL upon event creation
  useEffect(() => {
    if (familyEvent?.id && !navAtomProps.familyEventId) {
      nav(`/schools/${navAtomProps.schoolId}/family-events/${familyEvent?.id}`, { replace: true });
      setFamilyEvent(RESET);
    }
  }, [familyEvent]);

  const saveValidation = createValidator(familyEvent?.id ? PutFamilyEventDto : PostFamilyEventDto);
  const submitValidation = createValidator(SubmitFamilyEventDto);

  const validate = (update: PostFamilyEventDto | PutFamilyEventDto): string[] => {
    const errors = (update.submittedAt ? Object.values(submitValidation(update)) : Object.values(saveValidation(update))) as string[];
    const totalAttended = (update.attendance ?? []).reduce((acc, curr) => acc + (curr.studentAttended ? 1 : 0) + curr.numberAttended, 0);

    return totalAttended ? errors : [...errors, 'None attended'];
  };

  const onSave = async (update?: PostFamilyEventDto | PutFamilyEventDto) => {
    if (!update) {
      toast({ description: 'Oops', status: 'error' });
      setSubmitClicked(false);
      return;
    }

    const errors = validate(update);
    let unhandledErrors = errors;
    // Handle None attended
    if (errors.length === 1 && errors[0] === 'None attended' && !isConfirmSaveModalOpen) {
      if (submitClicked) {
        setIsConfirmSaveModalOpen(true);
        return;
      }
    }
    unhandledErrors = errors.filter((txt) => txt !== 'None attended');

    // Handle other errors
    if (unhandledErrors.length > 0 && !isConfirmSaveModalOpen) {
      toast({ description: unhandledErrors[0], status: 'error' });
      return;
    }
    setIsConfirmSaveModalOpen(false);
    setIsSaving(true);
    await setFamilyEvent(update);
  };

  const performDelete = async () => {
    await deleteFamilyEvent({
      schoolId: school?.id as number,
      familyEventId: familyEvent?.id as number,
    });
    setIsDeleteModalOpen(false);
    toast({ description: 'Family event deleted', status: 'success' });
    nav(`/schools/${school?.id}/family-events`);
  };

  const SaveUpdate = (values: any): PostFamilyEventDto | PutFamilyEventDto => ({
    ...familyEvent,
    ...values,
    attendance: attendanceData,
  });

  const SubmitUpdate = (values: any): PutFamilyEventDto =>
    ({
      ...SaveUpdate(values),
      submittedAt: new Date().toISOString(),
    } as PutFamilyEventDto);

  interface CellCheckboxProps {
    id: string;
    isChecked: boolean;
    row: any;
    isDisabled: boolean;
  }

  const CellCheckbox: React.FunctionComponent<CellCheckboxProps> = (props: CellCheckboxProps) => (
    <Checkbox
      id={props.id}
      isChecked={props.isChecked}
      isDisabled={props.isDisabled}
      bg="ff.white"
      onChange={() =>
        setAttendanceData(
          attendanceData.map((a) => (a.student.id === props.row.student.id ? { ...a, ...{ [props.id]: !props.isChecked } } : a)),
        )
      }
    />
  );

  interface CellNumberInputProps {
    id: string;
    value: number;
    row: any;
    isDisabled: boolean;
  }

  const CellNumericInput: React.FunctionComponent<CellNumberInputProps> = (props: CellNumberInputProps) => (
    <NumberInput
      id={props.id}
      w="75px"
      min={0}
      max={20}
      value={props.value}
      isDisabled={props.isDisabled}
      onChange={(valueAsString: string, valueAsNumber: number) =>
        setAttendanceData(
          attendanceData.map((a) => (a.student.id === props.row.student.id ? { ...a, ...{ [props.id]: valueAsNumber } } : a)),
        )
      }
      isReadOnly={false}
    >
      <NumberInputField bg="ff.white" borderColor={'ff.magenta'} borderWidth="2px" />
      <NumberInputStepper>
        <NumberIncrementStepper m="1px" />
        <NumberDecrementStepper m="1px" />
      </NumberInputStepper>
    </NumberInput>
  );

  const getStudentAttendedCount = () => _.sumBy(attendanceData, (a) => a.studentAttended);
  const getNumberRegisteredCount = () => _.sumBy(attendanceData, (a) => a.numberRegistered);
  const getNumberAttendedCount = () => _.sumBy(attendanceData, (a) => a.numberAttended);

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => row.student,
      id: 'fullName',
      cell: (info: any) => StudentLink(info.getValue()),
      header: () => 'Student',
      sortingFn: studentSort,
    },
    {
      accessorFn: (row) => row,
      id: 'studentAttended',
      cell: (info: any) => (
        <CellCheckbox
          id="studentAttended"
          isChecked={info.getValue().studentAttended}
          isDisabled={isSaving || isSubmitted}
          row={info.getValue()}
        />
      ),
      header: () => 'Student Attended?',
      footer: () => `${getStudentAttendedCount()} ${getStudentAttendedCount() === 1 ? 'Student' : 'Students'} Attended`,
    },
    {
      accessorFn: (row) => row,
      id: 'numberRegistered',
      cell: (info: any) => (
        <CellNumericInput
          id="numberRegistered"
          value={info.getValue().numberRegistered}
          isDisabled={isSaving || isSubmitted}
          row={info.getValue()}
        />
      ),
      header: () => 'Family Members Registered',
      footer: () => `${getNumberRegisteredCount()} ${getNumberRegisteredCount() === 1 ? 'Family Member' : 'Family Members'} Registered`,
      sortingFn: columnIdValueSort,
    },
    {
      accessorFn: (row) => row,
      id: 'numberAttended',
      cell: (info: any) => (
        <CellNumericInput
          id="numberAttended"
          value={info.getValue().numberAttended}
          isDisabled={isSaving || isSubmitted}
          row={info.getValue()}
        />
      ),
      header: () => 'Family Members Attended',
      footer: () => `${getNumberAttendedCount()} ${getNumberAttendedCount() === 1 ? 'Family Member' : 'Family Members'} Attended`,
    },
    {
      accessorFn: (row) => row,
      id: 'link',
      cell: (info: any) => (school ? StudentSummaryLink(school, info.getValue().student, 'See Family Engagement') : ''),
      header: '',
      enableColumnFilter: false,
    },
  ];
  const handleDateChange = (date: Date) => {
    console.log('DATE CHANGE: ', date);
    setEventDateFilter(date);
  };
  // ={() => onSave(submitClicked ? SubmitUpdate(formRef.current?.values) : SaveUpdate(formRef.current?.values))}
  return (
    <Container maxWidth="100%" fontSize="12px">
      <Flex>
        <Box flex={1}>
          <Heading color={'ff.blue'}> {eventScreenTitle()} Event</Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: `/schools/${school?.id}/family-events/`, // TODO: update when exists
                name: 'See All Family Events',
              },
              {
                path: `/schools/${school?.id}/family-interactions/`, // TODO: update when exists
                name: 'See All Family Engagements',
              },
              ...(familyEvent
                ? [
                    {
                      name: 'Delete',
                      clickCb: () => setIsDeleteModalOpen(true),
                    },
                  ]
                : []),
            ]}
          />
          <ConfirmAlert
            active={isDeleteModalOpen}
            headerText="Delete Family Event"
            bodyText="Are you sure? You can't undo this action afterwards."
            okHandle={performDelete}
            cancelHandle={() => setIsDeleteModalOpen(false)}
            continueText="Delete"
          />
          <ConfirmAlert
            active={isConfirmSaveModalOpen}
            headerText="Save Family Event"
            bodyText="Oops, it looks like nobody came. Are you sure you want to finalize the event with no attendance?"
            okHandle={() => onSave(submitClicked ? SubmitUpdate(formRef.current?.values) : SaveUpdate(formRef.current?.values))}
            cancelHandle={() => {
              setIsConfirmSaveModalOpen(false);
              setSubmitClicked(false);
              setIsSaving(false);
            }}
            continueText="Save"
          />
        </Box>
      </Flex>
      <FfDivider />
      {!isEventNotFound ? (
        <>
          <FamilyEventForm familyEvent={familyEvent} isLoading={isSaving} formRef={formRef} onDateChange={handleDateChange} />
          <Flex>
            <Box flex={1}>
              <Heading color={'ff.blue'} display="inline">
                Event Attendance
              </Heading>
              {familyEvent?.id && !isSubmitted && (
                <Heading
                  size="xs"
                  fontWeight="normal"
                  ml="10px"
                  textDecoration="underline"
                  color={'ff.magenta'}
                  display="inline"
                  cursor="pointer"
                  onClick={() => {
                    setLoadAttendanceUpdates(true);
                    setStudents(RESET);
                  }}
                >
                  Refresh Student List for selected Event Date
                </Heading>
              )}
            </Box>
          </Flex>
          {isRefreshSummaryVisible && (
            <Box bg={'ff.lightPink'} color={'ff.magenta'} mt="1em" p="1em" fontSize="14px">
              <Text>
                {newStudents.length > 0 && `${newStudents.length} new ${newStudents.length === 1 ? 'student' : 'students'}: `}
                {newStudents?.map((s) => s.fullName).join(', ')}
              </Text>
              <Text>
                {removedStudents.length > 0 &&
                  `${removedStudents.length} removed ${removedStudents.length === 1 ? 'student' : 'students'}: `}
                {removedStudents?.map((s) => s.fullName).join(', ')}
              </Text>
            </Box>
          )}
          <FfTable columns={columns} data={_.sortBy(attendanceData, (a) => a.student.fullName)} />
          <Box textAlign="right" flex={1}>
            <ButtonGroup spacing={6}>
              {familyEvent?.id && (
                <Button
                  isLoading={isSaving}
                  type={FfButtonTypes.Submit}
                  variant={'outline'}
                  disabled={isSubmitted}
                  onClick={() => {
                    setLoadAttendanceUpdates(false);
                    setFamilyEvent(RESET);
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                isLoading={isSaving}
                type={FfButtonTypes.Submit}
                variant={'outline'}
                disabled={isSubmitted}
                onClick={() => {
                  setSubmitClicked(false);
                  formRef.current?.handleSubmit();
                  onSave(SaveUpdate(formRef.current?.values));
                }}
              >
                Save
              </Button>
              <Button
                disabled={isSubmitted}
                isLoading={isSaving}
                variant={'cta'}
                onClick={() => {
                  setSubmitClicked(true);
                  formRef.current?.handleSubmit();
                  onSave(SubmitUpdate(formRef.current?.values));
                }}
              >
                {isSubmitted ? 'Submitted' : 'Submit'}
              </Button>
            </ButtonGroup>
          </Box>
        </>
      ) : (
        <Empty description="Event not found." />
      )}
      <DebugCard
        data={[
          {
            name: 'flags',
            data: {
              isSubmitted,
              isSaving,
            },
          },
          { name: 'familyEvent', data: familyEvent },
          { name: 'students', data: students },
          { name: 'attendanceData', data: attendanceData },
        ]}
      />
    </Container>
  );
};

/*

  // INITIALIZE
  const constructAttendanceData = () =>
    // If new event, construct attendance table data from student list, otherwise from attendance list
    (familyEvent?.id
      ? familyEvent?.attendance
      : students ?? []);

  const buildUpdatedStudentData = (pAttendanceData: (FamilyEventAttendanceDto | StudentDto)[]) => {
    const newStudentRows = [] as StudentDto[];
    const removedStudentRows = [] as StudentDto[];
    const newData = students.map((s) => {
      const originalMatch = familyEvent?.attendance?.find((a) => a.studentId === s.id);
      const currentListMatch = pAttendanceData.find((a) => a.student.id === s.id);

      let highlightClass = '';

      if (!originalMatch && s.active) {
        newStudentRows?.push(s);
        highlightClass = 'rowHighlightNew';
      } else if (originalMatch && !s.active) {
        removedStudentRows?.push(s);
        highlightClass = 'rowHighlightRemoved';
      }

      return currentListMatch
        ? {
            ...currentListMatch,
            ...{ rowHighlightClass: highlightClass },
          }
        : {
            rowHighlightClass: 'rowHighlightNew',
            student: s,
            studentId: s.id,
            studentAttended: false,
            numberRegistered: 0,
            numberAttended: 0,
          };
    });

    return { newStudentRows, removedStudentRows, newData };
  };
  */
