/* eslint-disable @typescript-eslint/naming-convention */

import { DistrictDto, DistrictTermDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { storedDistrictsAtom } from '../atoms/districtAtom';
import { persistNavAtom } from '../atoms/navAtom';
import { apiClientAtom } from '../ffApi';

const districtServiceFactory = (get: Getter) => ({
  getAllDistricts: async (): Promise<DistrictDto[]> => {
    const response = await get(apiClientAtom).get(`/districts/`);
    return response.data.results as DistrictDto[];
  },
  putDistrictTerms: async (id: number, terms: DistrictTermDto[]): Promise<DistrictTermDto[]> => {
    const response = await get(apiClientAtom).put(`/districts/${id}/terms`, terms);
    return response.data.results as DistrictTermDto[];
  },
});

const DistrictService = atom(districtServiceFactory);
export { DistrictService };

export const DistrictServiceAtoms = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAllDistricts: storedDistrictsAtom,
  getCurrentDistrict: atom((get) => {
    const nav = get(persistNavAtom);
    if (!nav.districtId) return undefined;
    const currentDistricts = get(storedDistrictsAtom);
    return currentDistricts.find((d) => d.id === nav.districtId);
  }),
};
