import { Container } from '@chakra-ui/react';
import React from 'react';
import { useAtom } from 'jotai';
import { EditStudentForm } from '../../components/EditStudent/EditStudentForm';
import { FfDivider } from '../../components/FfDivider';
import { StudentBanner } from '../../components/StudentBanner';
import { StudentServiceAtoms } from '../../services/Student';

export const EditStudentScreen: React.FunctionComponent = () => {
  const [student] = useAtom(StudentServiceAtoms.getCurrentStudent);

  return (
    <Container maxWidth="100%">
      {student && (
        <>
          <StudentBanner student={student} showEnrollmentLink={true} />
          <FfDivider />
          <EditStudentForm student={student} />
        </>
      )}
    </Container>
  );
};
