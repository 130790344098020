import { Box, Button, Text, Tooltip } from '@chakra-ui/react';
import {
  AttendanceStatusTypes,
  FFRoles,
  LessonStatusTypes,
  PutSessionLessonPlanDto,
  ReadingLevelTypes,
  RoleDto,
  SchoolDto,
  SessionLessonPlanDto,
  SessionObservationDto,
  StudentDto,
  UserDto,
} from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { DateTime as DT } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { FfRouterLink as Link } from '../components/FfRouterLink';
import { userInfoAtom } from '../ffApi';
import { SchoolServiceAtoms } from '../services/School';
import { Asc } from '../utils/TimeHelper';
import { getAsYearMonthDay } from '../utils/utils';
import { getGradeLevelTypeString } from './LessonPlanContent/models/GradeLevelEnum';
import { getReadingLevelTypeString } from './LessonPlanContent/models/ReadingLevelEnum';
import { FfTooltip } from './FfTooltip';

export { defaultCell, StudentColumn } from './UtilsTs';

const F = (dateTimeStr: string, format: string) => DT.fromISO(dateTimeStr).toFormat(format);

export const isEligibleForMakeUp = (lessonPlan: SessionLessonPlanDto) =>
  lessonPlan.lessonStatus === LessonStatusTypes.Submitted &&
  (lessonPlan.attendanceStatus === AttendanceStatusTypes.EventAbsence ||
    lessonPlan.attendanceStatus === AttendanceStatusTypes.StudentAbsence ||
    lessonPlan.attendanceStatus === AttendanceStatusTypes.TutorAbsence ||
    lessonPlan.attendanceStatus === AttendanceStatusTypes.Holiday);

export const luxonDays = (day: number) => (day + 6) % 7;

export const UserLink = (user: UserDto) => (
  <Link cursor="pointer" textDecoration="underline !important" to={`/users/${user?.id}`}>
    {user?.fullName}
  </Link>
);
export const StudentLink = (student: StudentDto) =>
  student ? (
    <Link cursor="pointer" textDecoration="underline !important" to={`/schools/${student?.schoolId}/students/${student?.id}`}>
      {student?.fullName}
    </Link>
  ) : (
    <Text>No Student</Text>
  );

export const StudentEnrollmentLink = ({ school, student, text }: { school?: SchoolDto; student: StudentDto; text?: string }) =>
  student && school ? (
    <Link textDecoration="underline !important" to={`/schools/${student.schoolId}/students/${student.id}/enrollment`}>
      {text ?? 'See Enrollment History'}
    </Link>
  ) : (
    <Text>No Student</Text>
  );

export const LessonPlanTutorLink = (lessonPlan: SessionLessonPlanDto) => (
  <Link cursor="pointer" textDecoration="underline !important" to={`/tutors/${lessonPlan.byUserId}`}>
    {lessonPlan.byUser?.fullName}
  </Link>
);

export const LessonPlanUrl = (lessonPlan: SessionLessonPlanDto): string =>
  `/schools/${lessonPlan.student?.schoolId}/students/${lessonPlan.student?.id}/lesson-plans/${lessonPlan.id}`;

export const MakeUpLessonPlanUrl = (lessonPlan: SessionLessonPlanDto): string =>
  `/schools/${lessonPlan.student?.schoolId}/students/${lessonPlan.student?.id}/lesson-plans/${lessonPlan.originalId}`;

export const MakeUpLessonPlanLink = (lessonPlan: SessionLessonPlanDto, label: string = 'Make-Up') => (
  <Link textDecoration="underline !important" display={'inline'} to={MakeUpLessonPlanUrl(lessonPlan)}>
    {label}
  </Link>
);

export const LessonPlanLink = (lessonPlan: SessionLessonPlanDto) => (
  <Link color={'#b23877'} textDecoration="underline" to={LessonPlanUrl(lessonPlan)}>
    {isEligibleForMakeUp(lessonPlan) ? `See Details/Make Up` : `See Details`}
    <span className="debug-hide"> (#{lessonPlan.id})</span>
  </Link>
);
export const StudentSummaryLink = (school: SchoolDto, student: StudentDto, linkText?: string) => (
  <Link cursor="pointer" textDecoration="underline !important" to={`/schools/${school?.id}/students/${student?.id}`}>
    {linkText || student?.fullName}
  </Link>
);
export const SchoolLink = ({ school }: { school: SchoolDto }) => (
  <Link className="link" to={`/schools/${school.id}/dashboard`} state={{ districtId: school.districtId }}>
    {school?.name}
  </Link>
);

export const TutorLink = (school: SchoolDto, user: UserDto) => (
  <Link cursor="pointer" textDecoration="underline !important" to={`/schools/${school?.id}/tutors/${user?.id}`}>
    {user?.fullName}
  </Link>
);

export const SchoolUserLink = (school: SchoolDto, user: UserDto) => (
  <Link cursor="pointer" textDecoration="underline !important" to={`/schools/${school?.id}/users/${user?.id}`}>
    {user?.fullName}
  </Link>
);

export const TutorIdNameLink = (tutorId: number, tutorName: string) => (
  <>
    <Link cursor="pointer" textDecoration="underline !important" to={`/tutors/${tutorId}`}>
      {tutorName || <span>Tutor Name</span>}
    </Link>{' '}
    {!tutorName && <span className="devnote">NIM</span>}
  </>
);

export const CreateLessonLinkUrl = (lessonPlan: SessionLessonPlanDto, withObservation: boolean = false) => {
  const createQuery = !lessonPlan.id ? `?weekOrdinal=${lessonPlan.weekOrdinal}&date=${getAsYearMonthDay(lessonPlan.startDateTime)}` : '';
  // this is not nested by the way, it's chained ternary
  const createLink =
    // eslint-disable-next-line no-nested-ternary
    lessonPlan?.id !== undefined
      ? ''
      : withObservation
      ? `/schools/${lessonPlan.student?.schoolId}/students/${lessonPlan.student?.id}/observe/create${createQuery}`
      : `/schools/${lessonPlan.student?.schoolId}/students/${lessonPlan.student?.id}/lesson-plans/create${createQuery}`;

  return lessonPlan.id
    ? `/schools/${lessonPlan.student?.schoolId}/students/${lessonPlan.student?.id}/lesson-plans/${lessonPlan.id}`
    : createLink;
};

export const CreateLessonLinkUrl2 = (lessonPlan: SessionLessonPlanDto, withObservation: boolean = false) => {
  const createQuery = `?weekOrdinal=${lessonPlan.weekOrdinal}&date=${getAsYearMonthDay(lessonPlan.startDateTime)}`;

  return lessonPlan.id && withObservation
    ? `/schools/${lessonPlan.student?.schoolId}/tutors/${lessonPlan.byUserId}/observe/create${createQuery}`
    : `/schools/${lessonPlan.student?.schoolId}/students/${lessonPlan.student?.id}/lesson-plans/create${createQuery}`;
};
export const CreateLessonLink = (lessonPlan: SessionLessonPlanDto) => (
  <Link cursor="pointer" textDecoration="underline !important" color={'ff.magenta'} to={CreateLessonLinkUrl(lessonPlan)}>
    {
      // eslint-disable-next-line no-nested-ternary
      !lessonPlan.id ? 'Start Lesson Plan' : isEligibleForMakeUp(lessonPlan) ? 'See Details/Make Up' : 'See Details'
    }
  </Link>
);

export const isAbsenceLessonPlan = (lessonPlan: SessionLessonPlanDto | PutSessionLessonPlanDto) =>
  lessonPlan.attendanceStatus === AttendanceStatusTypes.EventAbsence ||
  lessonPlan.attendanceStatus === AttendanceStatusTypes.StudentAbsence ||
  lessonPlan.attendanceStatus === AttendanceStatusTypes.TutorAbsence ||
  lessonPlan.attendanceStatus === AttendanceStatusTypes.Holiday;

export const LessonPlanSessionCardStatus = (lessonStatus: LessonStatusTypes) => {
  switch (lessonStatus) {
    case LessonStatusTypes.Scheduled:
      return 'Start Lesson Plan';
    case LessonStatusTypes.InProgress:
      return 'Lesson Plan In Progress';
    case LessonStatusTypes.Submitted:
      return 'See Lesson Plan';
    case LessonStatusTypes.Absence:
      return 'Absence Lesson Plan';
    default:
      throw new Error('No defined value!');
  }
};

export const ObservationSessionLinkUrl = (
  session: SessionLessonPlanDto | SessionObservationDto,
  theSchool: SchoolDto,
  theTutor: UserDto,
) => {
  let absence = false;
  let scheduledNotStarted = false;
  let noObservations = false;
  let submitted = false;
  const lessonPlan = 'attendanceStatus' in session ? session : session.sessionLessonPlan!;
  const observations = 'attendanceStatus' in session ? session.observations : [session];

  scheduledNotStarted = lessonPlan.lessonStatus === LessonStatusTypes.Scheduled && !!lessonPlan.student && !!lessonPlan.student.schoolId;

  noObservations = (observations?.length ?? 0) === 0;

  submitted = _.filter(observations, (o) => (o.submittedByUserId ?? 0) > 0).length > 0;

  absence = isAbsenceLessonPlan(lessonPlan);

  let label: string;
  let linkTo: string | undefined;
  if (absence) {
    label = 'Cannot Observe';
  } else if (scheduledNotStarted) {
    label = 'Observe';
    linkTo = CreateLessonLinkUrl(lessonPlan, true);
  } else if (noObservations) {
    label = 'Observe';
    linkTo = `/schools/${theSchool.id}/tutors/${theTutor.id}/observe/${lessonPlan.id}`;
  } else {
    // has observations
    label = submitted ? 'See Observation' : 'Edit Observation';
    linkTo = `/schools/${theSchool.id}/tutors/${theTutor.id}/observe/${lessonPlan.id}`;
  }

  return { linkTo, label };
};

export const ObservationLink = (
  sessionObservation: SessionLessonPlanDto | SessionObservationDto,
  theSchool: SchoolDto,
  theTutor: UserDto,
) => {
  const { linkTo, label } = ObservationSessionLinkUrl(sessionObservation, theSchool, theTutor);

  return linkTo ? (
    <Link cursor="pointer" margin={'1'} textDecoration="underline !important" to={linkTo}>
      {label}
    </Link>
  ) : (
    <Text margin={'1'} display={'inline'}>
      {label}
    </Text>
  );
};

export const getDateExclusions = (enrollments?: any[]) => {
  if (!enrollments || enrollments.length === 0) {
    return [{ start: new Date(), end: new Date() }];
  }
  // Sort the enrollments by entry date in ascending order
  enrollments.sort(Asc('entryDate'));

  const exclusions = [];

  // Add first exclusion from beginning of time to the earliest entry date
  const earliestEntryDate = new Date(enrollments[0].entryDate);
  earliestEntryDate.setDate(earliestEntryDate.getDate() - 1);

  exclusions.push({
    start: new Date(0),
    end: earliestEntryDate,
  });

  // Iterate through each enrollment and add exclusions for the gap between exit and entry dates
  for (let i = 0; i < enrollments.length - 1; i += i + 1) {
    const endDate = new Date(enrollments[i + 1].entryDate);
    endDate.setDate(endDate.getDate() - 1);

    const startDate = new Date(enrollments[i].exitDate);
    startDate.setDate(startDate.getDate() + 1);

    exclusions.push({
      start: startDate,
      end: endDate,
    });
  }

  // Add last exclusion from the latest exit date to the end of time. If no
  if (enrollments[enrollments.length - 1].exitDate) {
    const startDate = new Date(enrollments[enrollments.length - 1].exitDate);
    startDate.setDate(startDate.getDate() + 1);
    exclusions.push({
      start: startDate,
      end: new Date(2100, 0, 1),
    });
  }

  return exclusions;
};

const studentSlug = (p: { id?: number; studentId?: number }) => (p.id ?? p.studentId ? `/students/${p.id ?? p.studentId}` : '');
export const FamilyInteractionUrl = (p: { id?: number; schoolId?: number; studentId?: number; familyInteractionId?: number }) =>
  p.schoolId && `/schools/${p.schoolId}${studentSlug(p)}/family-interaction/${p.familyInteractionId ?? 'create'}`;

export const CreateFamilyInteractionButton = (props: { id?: number; schoolId?: number; studentId?: number }) => {
  const nav = useNavigate();
  const url = FamilyInteractionUrl(props);
  return url ? <Button onClick={() => nav(url)}>Add a Family Interaction</Button> : <></>;
};

export const FamilyInteractionLink = (familyInteractionId: number, schoolId?: number) => (
  <>
    {schoolId && familyInteractionId && (
      <Link color={'#b23877'} textDecoration="underline" to={`/schools/${schoolId}/family-interaction/${familyInteractionId}`}>
        See Details
        <span className="debug-hide"> (#{familyInteractionId})</span>
      </Link>
    )}
  </>
);

export const FamilyEventLink = (familyEventId: number, schoolId?: number) => (
  <>
    {schoolId && familyEventId && (
      <Link color={'#b23877'} textDecoration="underline" to={`/schools/${schoolId}/family-events/${familyEventId}`}>
        See Details
        <span className="debug-hide"> (#{familyEventId})</span>
      </Link>
    )}
  </>
);

export const HomeUrl = () => {
  const [userInfo] = useAtom(userInfoAtom);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  let homeLink = '/schools';
  if (school !== undefined) {
    if (userInfo.roleId === FFRoles.Tutor) {
      homeLink = `/schools/${school.id}/tutors/${userInfo.id}/dashboard`;
    } else {
      homeLink = `/schools/${school.id}/dashboard`;
    }
  }
  return homeLink;
};

export const UserEditLink = (user: UserDto) => (
  <Link cursor="pointer" textDecoration="underline !important" to={`/users/${user?.id}/edit`}>
    Edit
  </Link>
);

export const NameTextList = (items: any[]) => items?.map((item: any) => item?.name).join(', ');

export const LongFormatDateTime = (dateTimeStr: string) =>
  F(dateTimeStr, 'cccc, LLLL d, h') + (F(dateTimeStr, 'm') !== '0' ? F(dateTimeStr, ':mm ') : ' ') + F(dateTimeStr, 'a');
export const ShortFormatDateTime = (dateTimeStr: string) =>
  F(dateTimeStr, 'ccc LLL d, h') + (F(dateTimeStr, 'm') !== '0' ? F(dateTimeStr, ':mm ') : ' ') + F(dateTimeStr, 'a');
export const LongFormatTime = (dateTimeStr: string) =>
  F(dateTimeStr, 'h') + (F(dateTimeStr, 'm') !== '0' ? F(dateTimeStr, ':mm ') : ' ') + F(dateTimeStr, 'a');

export const SlugFormatDateTime = (dateTimeStr: string) =>
  F(dateTimeStr, 'ccc LLLL d, h') + (F(dateTimeStr, 'm') !== '0' ? F(dateTimeStr, ':mm ') : ' ') + F(dateTimeStr, 'a');
export const SlugFormatTime = (dateTimeStr: string) =>
  F(dateTimeStr, 'h') + (F(dateTimeStr, 'm') !== '0' ? F(dateTimeStr, ':mm ') : ' ') + F(dateTimeStr, 'a');

export const CompactFormatDateString = (dateTimeStr: string) => DT.fromISO(dateTimeStr).toFormat('ccc LLL d, y');

export const TableFormatDateTime = 'ccc LLL d, h:mm a';
export const TableFormatDateTimeString = (dateTimeStr: string, zone?: string) =>
  DT.fromISO(dateTimeStr, zone ? { zone } : {}).toFormat(TableFormatDateTime);

export const ShortFormatDate = (dateTimeStr: string | Date | undefined) =>
  dateTimeStr && (typeof dateTimeStr === 'object' ? DT.fromJSDate(dateTimeStr) : DT.fromISO(dateTimeStr)).toFormat('LL/dd/yy');
export const ShortMonthYearFormatDateString = (dateTimeStr: string) => DT.fromISO(dateTimeStr).toFormat('LL/yy');
export const MonthYearFormat = (dateTimeStr: string, zone?: string) => DT.fromISO(dateTimeStr, zone ? { zone } : {}).toFormat('LLLL y');
export const DayMinutesToHour = (x: number) => DT.fromSeconds(0, { zone: 'utc' }).plus({ minutes: x }).toFormat('h:mma');

export const CompactFormatDate = (date: Date) => DT.fromJSDate(date).toFormat('ccc LLLL d, y');

export const getRoundedDate = (minutes: number, date = new Date()) => {
  const ms = 1000 * 60 * minutes;
  const roundedDate = new Date(Math.ceil(date.getTime() / ms) * ms);

  return roundedDate;
};

export const LessonPlanTimeString = (lessonPlan: SessionLessonPlanDto) =>
  `${SlugFormatTime(lessonPlan.startDateTime)} - ${SlugFormatTime(lessonPlan.endDateTime)}`;

export const LessonPlanStartEndTime = (lessonPlan: SessionLessonPlanDto) =>
  `${CompactFormatDateString(lessonPlan.startDateTime)} ${SlugFormatTime(lessonPlan.startDateTime)} - ${SlugFormatTime(
    lessonPlan.endDateTime,
  )}`;

export const isValidDateTimeString = (dateTimeStr: string) => DT.fromISO(dateTimeStr).isValid;

export const formatPhoneNumber = (phoneNumber: string) => {
  // Filter only numbers from the input
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const GradeLevelLabel = (gradeLevel?: number) => (
  <span>{(gradeLevel ?? undefined) !== undefined ? getGradeLevelTypeString(gradeLevel!) : 'NOT PROVIDED'}</span>
);
export const ReadingLevelLabel = (readingLevel?: number) => (
  <span>{getReadingLevelTypeString(readingLevel ?? ReadingLevelTypes.Unspecified)}</span>
);
export const TutorLabel = (user: UserDto) => <span>{user?.fullName}</span>;
export const TeacherLabel = (teacher: string) => <span>{teacher}</span>;
export const RoleLabel = (role: RoleDto) => <span>{role?.name ?? 'Unassigned'}</span>;
export const StudentStatusLabel = (active: boolean) => <span>{active ? 'Active' : 'Inactive'}</span>;
export const StudentStatusLabelAsOfDate = (student: StudentDto) => (
  <span>{student.active ? ShortFormatDate(student.activatedAt) : ShortFormatDate(student.deactivatedAt)}</span>
);

export const calculateAttendanceScore = (sessions: SessionLessonPlanDto[]): { rate: string; present: string; total: string } => {
  // (present + make-up)/(present + student absent + tutor absent + event absent)
  const numPresent = sessions.reduce(
    (p, c) => (c.attendanceStatus === AttendanceStatusTypes.Attended && c.lessonStatus === LessonStatusTypes.Submitted ? p + 1 : p),
    0,
  );
  const numTotal = sessions.reduce((p, c) => (c.attendanceStatus !== AttendanceStatusTypes.Scheduled ? p + 1 : p), 0);
  const rate = numTotal !== 0 ? `${(Math.round((numPresent / numTotal) * 10000) / 100).toFixed(2)}%` : `N/A`;
  const total = numTotal?.toString();
  const present = numPresent.toString();
  return { rate, present, total };
};

export const studentStatusColor = (student: StudentDto) => (student.active ? 'ff.darkGray' : 'ff.lightGray');

export const RenderHTML = (text: string) => <div dangerouslySetInnerHTML={{ __html: text }} />;
